import { Collapse, Col, Row } from 'reactstrap';
import ChildMenuItem from './ChildMenuItem';

const MenuItem = ({ active, icon, title, child, ...otherProps }) => {
    const Icon = icon;
    return (
        <Col className="p-0">
            <Row
                className="nav-item m-0"
                {...otherProps}
            >
                <Icon fill={active ? "#1C37BE" : "#ABABAB"}/>
                <span
                    className={`ml-3 title ${active && "nav-active"}`}
                >
                    {title}
                </span>
            </Row>
            {child && (
                <Collapse isOpen={active}>
                    <Col>
                        {child.map((item, index) => {
                            const { link, title, icon } = item;
                            return (
                                <ChildMenuItem
                                    key={index}
                                    link={link}
                                    icon={icon}
                                    title={title}
                                />
                            )
                        })}
                    </Col>
                </Collapse>
            )}
        </Col>
    )
}

export default MenuItem
