import moment from "moment";

export const regexPassword = /^.*(?=.{8,})(?=.*\d)(?=.*[a-zA-Z]).*$/;
export const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const roleAdminOptions = [
  {
    value: "superadmin",
    label: "superadmin",
  },
  {
    value: "administrator",
    label: "administrator",
  },
];

export const roleEmployeeOptions = [
  {
    value: "avp",
    label: "AVP",
  },
  {
    value: "rh",
    label: "Regional Head",
  },
  {
    value: "gm",
    label: "General Manager",
  },
  {
    value: "technical_support",
    label: "Technical Support",
  },
  {
    value: "sales_team",
    label: "Sales Team",
  },
  {
    value: "finance",
    label: "Finance",
  },
  {
    value: "sales_admin",
    label: "Sales Admin",
  },
];

export const roleOptions = [
  {
    value: "superadmin",
    label: "superadmin",
  },
  {
    value: "administrator",
    label: "administrator",
  },
  {
    value: "avp",
    label: "AVP",
  },
  {
    value: "rh",
    label: "Regional Head",
  },
  {
    value: "gm",
    label: "General Manager",
  },
  {
    value: "technical_support",
    label: "Technical Support",
  },
  {
    value: "sales_team",
    label: "Sales Team",
  },
  {
    value: "finance",
    label: "Finance",
  },
  {
    value: "sales_admin",
    label: "Sales Admin",
  },
];

export const statusOrderOptions = [
  {
    value: 0,
    label: "DRAFT",
    bgColor: '#A5A5C0'
  },
  {
    value: 1,
    label: "BARU",
    bgColor: '#E8A720'
  },
  {
    value: 2,
    label: "DIPROSES",
    bgColor: '#E8A720'
  },
  {
    value: 3,
    label: "DIKIRIM",
    bgColor: '#E8A720'
  },
  {
    value: 4,
    label: "SELESAI",
    bgColor: '#1CB289'
  },
];

export const typeOrderOptions = [
  {
    value: "PS",
    label: "Poultry Shop",
  },
  {
    value: "FARM",
    label: "Farm",
  },
];

export const typeSoquoOptions = [
  {
    value: "SO",
    label: "SO",
  },
  {
    value: "QUO",
    label: "QUO",
  },
];

export const statusSoquoOptions = [
  {
    value: 0,
    label: "BARU",
  },
  {
    value: 1,
    label: "DITERUSKAN",
  },
  {
    value: 2,
    label: "DITOLAK",
  },
  {
    value: 3,
    label: "DISETUJUI",
  },
];

export const monthsOptions = moment.months().map((item, key) => {
  return {
    label: item,
    value: key + 1,
  };
});

export const auditTrailAction = [
  {
    type: "MASTER_DATA_PRODUCT",
    route: "/produk",
    activeMenu: "Master Data",
  },
  {
    type: "MASTER_DATA_FACTORY",
    route: "/pabrik",
    activeMenu: "Master Data",
  },
  {
    type: "MASTER_DATA_CITY",
    route: "/kota",
    activeMenu: "Master Data",
  },
  {
    type: "MASTER_DATA_REGION",
    route: "/wilayah",
    activeMenu: "Master Data",
  },
  {
    type: "MASTER_DATA_AREA",
    route: "/area",
    activeMenu: "Master Data",
  },
  {
    type: "MASTER_DATA_EMPLOYEE",
    route: "/pegawai",
    activeMenu: "Master Data",
  },
  {
    type: "MASTER_DATA_OWNER",
    route: "/owner",
    activeMenu: "Master Data",
  },
  {
    type: "MASTER_DATA_CUSTOMER",
    route: "/customer",
    activeMenu: "Master Data",
  },
  {
    type: "MASTER_DATA_USER",
    route: "/user",
    activeMenu: "Master Data",
  },
  {
    type: "ORDER",
    route: "/transaksi",
    activeMenu: "Transaksi",
  },
  {
    type: "SOQUO",
    route: "/so-quo",
    activeMenu: "SO/QUO",
  },
];
