import React from "react";
import { bool } from "prop-types";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";
import API from "../../../../store/actions/API";
import getToken from "../../../../utils/token";
const { ValueContainer, Placeholder } = components;

const custom = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props}>
        {props.selectProps.inputValue || props.hasValue ? (
          <span
            style={{
              position: "absolute",
              fontSize: 12,
              transform: "translateY(-30px)",
              whiteSpace: "nowrap",
            }}
          >
            {props.selectProps.placeholder}
          </span>
        ) : (
          props.selectProps.placeholder
        )}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const colourStyles = {
  control: (style, state) => {
    const borderColor = state.selectProps.isError ? "#ff1b1b" : "#ececec";
    return {
      ...style,
      backgroundColor: "transparent",
      color: "#EFF0F2",
      borderColor: "transparent",
      borderLeft: "none",
      boxShadow: null,
      borderBottom: `1px solid ${borderColor}`,
      fontSize: 14,
      padding: 0,
      borderRadius: 0,
      "&:hover": {
        borderBottom: "1px solid #1c37be",
      },
      "&:active": {
        borderBottom: "1px solid #1c37be",
      },
      "&:focus": {
        borderBottom: "1px solid #1c37be",
      },
    };
  },
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    // display: "flex",
    overflow: "visible",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    fontSize: 14,
    zIndex: 9999,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: "transparent",
  }),
  input: (styles) => {
    return {
      ...styles,
      margin: "5px 0 10px",
      "& input": {
        font: "inherit",
      },
    };
  },
  placeholder: (styles) => ({
    ...styles,
    fontSize: 14,
    opacity: 0.68,
    margin: 0,
    width: "100%",
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 14,
    marginLeft: 0,
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "transparent",
      marginLeft: 0,
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    paddingLeft: 0,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#ababab",
    "&:hover": {
      cursor: "pointer",
    },
  }),
};

const SelectComponent = (props) => {
  const {
    asyncUrl,
    isAsync,
    options,
    isClearable,
    placeholder,
    param = {},
  } = props;
  const delayedQuery = debounce((inputValue, callback) => {
    param.search_text = inputValue;
    API.get(asyncUrl, { params: param }).then((res) => {
      callback(
        res.data.data.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      );
    });
  }, 500);

  const loadOptions = (inputValue, callback) => {
    delayedQuery(inputValue, callback);
  };

  return isAsync ? (
    <AsyncSelect
      isClearable={isClearable}
      styles={{ ...colourStyles }}
      loadOptions={loadOptions}
      placeholder={placeholder}
      components={{ ValueContainer: custom }}
      defaultOptions={options}
      {...props}
    />
  ) : (
    <Select
      isClearable={isClearable}
      placeholder={placeholder}
      components={{ ValueContainer: custom }}
      styles={{ ...colourStyles }}
      {...props}
    />
  );
};

SelectComponent.propsTypes = {
  isClearable: bool,
};
SelectComponent.defaultProps = {
  isClearable: true,
};

export default SelectComponent;
