import {
  GET_FACTORY_PENDING,
  GET_FACTORY_SUCCESS,
  GET_FACTORY_ERROR,
  POST_FACTORY_PENDING,
  POST_FACTORY_SUCCESS,
  POST_FACTORY_ERROR,
  PUT_FACTORY_PENDING,
  PUT_FACTORY_SUCCESS,
  PUT_FACTORY_ERROR,
  DELETE_FACTORY_PENDING,
  DELETE_FACTORY_SUCCESS,
  DELETE_FACTORY_ERROR,
  FACTORY_DATA,
  SET_FACTORY_MODAL,
} from "../../../actions/master-data/factory";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const factory = (state = initialState, action) => {
  switch (action.type) {
    case GET_FACTORY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_FACTORY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_FACTORY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_FACTORY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_FACTORY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_FACTORY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_FACTORY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_FACTORY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_FACTORY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case FACTORY_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_FACTORY_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default factory;
