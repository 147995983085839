import React, { useState } from "react";
import UploadImage from "../../../../assets/img/badge_download@2x.png";
import "./file.scss";

export default ({ placeholder, fileType, value, rawFile, ...resProps }) => {
  const [image, setImage] = useState({ preview: "", raw: "" });
  if (rawFile && image.preview === "") {
    setImage({
      preview: URL.createObjectURL(rawFile[0]),
      raw: rawFile[0],
    });
  }

  return (
    <div>
      <label className="mb-3 placeholder">{placeholder}</label>
      <label htmlFor="upload-button">
        {image.preview ? (
          <img src={image.preview} alt="dummy" width="150" />
        ) : (
          <>
            <img
              src={UploadImage}
              alt="upload image"
              width="150"
              height="150"
            />
          </>
        )}
      </label>
      <input
        {...resProps}
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        accept={
          fileType === "image"
            ? "image/jpg, image/jpeg, image/png"
            : fileType === "pdf"
            ? ".pdf"
            : fileType === "pdf/image"
            ? ".pdf, image/jpg, image/jpeg, image/png"
            : fileType === "support"
            ? ".pdf, .xls, .xlsx, .doc, .docx, image/jpg, image/jpeg, image/png"
            : fileType === "excel"
            ? ".xls, .xlsx"
            : "FILE"
        }
      />
    </div>
  );
};
