import {
  GET_USER_PENDING,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  POST_USER_PENDING,
  POST_USER_SUCCESS,
  POST_USER_ERROR,
  PUT_USER_PENDING,
  PUT_USER_SUCCESS,
  PUT_USER_ERROR,
  DELETE_USER_PENDING,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  RESET_USER_PASSWORD_PENDING,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_ERROR,
  CHANGE_PASSWORD_USER_PENDING,
  CHANGE_PASSWORD_USER_SUCCESS,
  CHANGE_PASSWORD_USER_ERROR,
  USER_DATA,
  SET_USER_MODAL,
} from "../../../actions/master-data/user";

const initialState = {
  newPass: null,
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case RESET_USER_PASSWORD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case RESET_USER_PASSWORD_SUCCESS:
      const newState = {
        ...state,
        newPass: action.data.data.data.new_password,
        pending: false,
      };
      return newState;
    case RESET_USER_PASSWORD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case CHANGE_PASSWORD_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CHANGE_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case CHANGE_PASSWORD_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case USER_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_USER_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default user;
