import {
  GET_DASHBOARD_PENDING,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
} from "../../actions/dashboard";

const initialState = {
  pending: false,
  error: null,
  data: null,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DASHBOARD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default dashboard;
