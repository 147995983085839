import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_REPORT_PRODUCT_PENDING = "GET_REPORT_PRODUCT_PENDING";
export const GET_REPORT_PRODUCT_SUCCESS = "GET_REPORT_PRODUCT_SUCCESS";
export const GET_REPORT_PRODUCT_ERROR = "GET_REPORT_PRODUCT_ERROR";
export const EXPORT_REPORT_PRODUCT_PENDING = "EXPORT_REPORT_PRODUCT_PENDING";
export const EXPORT_REPORT_PRODUCT_SUCCESS = "EXPORT_REPORT_PRODUCT_SUCCESS";
export const EXPORT_REPORT_PRODUCT_ERROR = "EXPORT_REPORT_PRODUCT_ERROR";

export const REPORT_PRODUCT_DATA = "REPORT_PRODUCT_DATA";
export const SET_REPORT_PRODUCT_MODAL = "SET_REPORT_PRODUCT_MODAL";

// URL: URL_{URL}
const REPORT_PRODUCT_URL = "/v1/report/transaction_product";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_PRODUCT_PENDING));
  API.get(REPORT_PRODUCT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_PRODUCT_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_PRODUCT_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const exportExcel = (param) => (dispatch) => {
  dispatch(actionPending(EXPORT_REPORT_PRODUCT_PENDING));
  API.get(`${REPORT_PRODUCT_URL}/export_excel`, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(EXPORT_REPORT_PRODUCT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Laporan Order Berdasarkan Produk.xlsx");
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((err) => {
      dispatch(actionError(EXPORT_REPORT_PRODUCT_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const ReportProduct = { get, exportExcel };
export default ReportProduct;

export const setReportProductData = (data) => (dispatch) =>
  dispatch({ type: REPORT_PRODUCT_DATA, data });

export const setReportProductModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_REPORT_PRODUCT_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
