import React from "react";
import { Container, Row } from "reactstrap";
import Logo from "../../commons/logo";
import Account from "./account";
import { getUser } from "../../../utils/user";

const Index = () => {
  return (
    <Row className="m-0">
      <Container className="d-flex justify-content-between py-4 px-0 m-auto">
        <Logo />
        <Account fullName={getUser()?.name} email={getUser()?.email} />
      </Container>
    </Row>
  );
};

export default Index;
