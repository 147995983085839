import {
  GET_SUPERVISOR_PENDING,
  GET_SUPERVISOR_SUCCESS,
  GET_SUPERVISOR_ERROR,
  POST_SUPERVISOR_PENDING,
  POST_SUPERVISOR_SUCCESS,
  POST_SUPERVISOR_ERROR,
  PUT_SUPERVISOR_PENDING,
  PUT_SUPERVISOR_SUCCESS,
  PUT_SUPERVISOR_ERROR,
  DELETE_SUPERVISOR_PENDING,
  DELETE_SUPERVISOR_SUCCESS,
  DELETE_SUPERVISOR_ERROR,
  RESET_SUPERVISOR_PASSWORD_PENDING,
  RESET_SUPERVISOR_PASSWORD_SUCCESS,
  RESET_SUPERVISOR_PASSWORD_ERROR,
  SUPERVISOR_DATA,
  SET_SUPERVISOR_MODAL,
} from "../../../actions/master-data/supervisor";

const initialState = {
  newPass: null,
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const supervisor = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPERVISOR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_SUPERVISOR_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_SUPERVISOR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_SUPERVISOR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_SUPERVISOR_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_SUPERVISOR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_SUPERVISOR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_SUPERVISOR_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_SUPERVISOR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_SUPERVISOR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_SUPERVISOR_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_SUPERVISOR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case RESET_SUPERVISOR_PASSWORD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case RESET_SUPERVISOR_PASSWORD_SUCCESS:
      const newState = {
        ...state,
        newPass: action.data.data.data.new_password,
        pending: false,
      };
      return newState;
    case RESET_SUPERVISOR_PASSWORD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SUPERVISOR_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_SUPERVISOR_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default supervisor;
