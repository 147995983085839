import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Modal, ModalBody } from "reactstrap";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import "./layout.scss";
import { setActiveMenu, setToastModal } from "../../store/actions";

const Index = ({ children, global, onSetActiveMenu, onSetToastModal }) => {
  const [isActive, setIsActive] = useState(false);
  const toggle = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    if (global.isOpen) {
      setTimeout(() => {
        onSetToastModal(false, false, "");
      }, 2000);
    }
  }, [global.isOpen]);
  const hasSidebar = window.location.pathname.split("/").length <= 2;
  return (
    <div>
      {global.modalBody && global.modalBody !== "" && (
        <Modal
          className="modal-small"
          isOpen={global.isOpen}
          backdrop
          keyboard
          centered
        >
          <ModalBody>
            <div className="d-flex flex-column text-center">
              {global.isSuccess ? (
                <CheckCircleIcon
                  style={{
                    color: "#1c37be",
                    fontSize: 72,
                    alignSelf: "center",
                    margin: 20,
                  }}
                />
              ) : (
                global.modalBody &&
                global.modalBody !== "" && (
                  <CancelIcon
                    style={{
                      color: "#707070",
                      fontSize: 72,
                      alignSelf: "center",
                      margin: 20,
                    }}
                  />
                )
              )}
              <label style={{ fontWeight: 700, fontSize: 18 }}>
                {global.modalBody}
              </label>
            </div>
          </ModalBody>
        </Modal>
      )}
      <div className="header-container">
        <Header />
      </div>
      {hasSidebar && (
        <div className="sidebar-menu-responsive">
          <div onClick={toggle} className={`responsive-icon d-inline-block`} >
            {!isActive ? (
              <MenuIcon style={{ fontSize: 32 }} />
            ) : (
              <MenuOpenIcon style={{ fontSize: 32 }} />
            )}
          </div>
        </div>
      )}
      <div className="hr-header"></div>
      <Container className="p-0 m-auto layout-container" >
        <Row className="content-layout d-flex m-0">
          {hasSidebar && (
            <Col
              lg={2}
              md={3}
              sm={4}
              xs={6}
              className={`sidebar-container p-0 d-lg-block ${
                isActive && "active"
              }`}
            >
              <div className={`d-flex`}>
                <Sidebar
                  selectedItem={global.activeMenu}
                  onChange={(menu) => onSetActiveMenu(menu)}
                  setIsActive={setIsActive} isActive={isActive}
                />
              </div>
            </Col>
          )}
          <Col
            lg={hasSidebar   ? 10 : 12}
            md={8}
            className={`d-flex p-0 ${
              !hasSidebar ? "w-100" : "pr-0"
            } main-content ${isActive && "active"}`}
          >
            {children}
          </Col>
        </Row>
      </Container>
      <div className="mb-3"></div>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ global }) => {
  return { global };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetActiveMenu: (menu) => dispatch(setActiveMenu(menu)),
    onSetToastModal: (isOpen, isSuccess, modalBody) =>
      dispatch(setToastModal(isOpen, isSuccess, modalBody)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
