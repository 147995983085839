import {
  GET_REPORT_CUSTOMER_PENDING,
  GET_REPORT_CUSTOMER_SUCCESS,
  GET_REPORT_CUSTOMER_ERROR,
  EXPORT_REPORT_CUSTOMER_PENDING,
  EXPORT_REPORT_CUSTOMER_SUCCESS,
  EXPORT_REPORT_CUSTOMER_ERROR,
  REPORT_CUSTOMER_DATA,
  SET_REPORT_CUSTOMER_MODAL,
} from "../../../actions/report/customer";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_CUSTOMER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_REPORT_CUSTOMER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_REPORT_CUSTOMER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case EXPORT_REPORT_CUSTOMER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EXPORT_REPORT_CUSTOMER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case EXPORT_REPORT_CUSTOMER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case REPORT_CUSTOMER_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_REPORT_CUSTOMER_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default customer;
