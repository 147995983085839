import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_REPORT_SOQUO_PENDING = "GET_REPORT_SOQUO_PENDING";
export const GET_REPORT_SOQUO_SUCCESS = "GET_REPORT_SOQUO_SUCCESS";
export const GET_REPORT_SOQUO_ERROR = "GET_REPORT_SOQUO_ERROR";
export const EXPORT_REPORT_SOQUO_PENDING = "EXPORT_REPORT_SOQUO_PENDING";
export const EXPORT_REPORT_SOQUO_SUCCESS = "EXPORT_REPORT_SOQUO_SUCCESS";
export const EXPORT_REPORT_SOQUO_ERROR = "EXPORT_REPORT_SOQUO_ERROR";
export const EXPORT_PDF_REPORT_SOQUO_PENDING =
  "EXPORT_PDF_REPORT_SOQUO_PENDING";
export const EXPORT_PDF_REPORT_SOQUO_SUCCESS =
  "EXPORT_PDF_REPORT_SOQUO_SUCCESS";
export const EXPORT_PDF_REPORT_SOQUO_ERROR = "EXPORT_PDF_REPORT_SOQUO_ERROR";

export const REPORT_SOQUO_DATA = "REPORT_SOQUO_DATA";
export const SET_REPORT_SOQUO_MODAL = "SET_REPORT_SOQUO_MODAL";

// URL: URL_{URL}
const REPORT_SOQUO_URL = "/v1/report/so_quo";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_SOQUO_PENDING));
  API.get(REPORT_SOQUO_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_SOQUO_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_SOQUO_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const exportExcel = (param) => (dispatch) => {
  dispatch(actionPending(EXPORT_REPORT_SOQUO_PENDING));
  API.get(`${REPORT_SOQUO_URL}/export_excel`, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(EXPORT_REPORT_SOQUO_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Laporan Permohonan SOQUO.xlsx");
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((err) => {
      dispatch(actionError(EXPORT_REPORT_SOQUO_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const exportPdf = (param) => (dispatch) => {
  dispatch(actionPending(EXPORT_REPORT_SOQUO_PENDING));
  API.get(`${REPORT_SOQUO_URL}/print`, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(EXPORT_REPORT_SOQUO_SUCCESS, res));
      // const url = window.URL.createObjectURL(new Blob([res.data]));
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", "Laporan Permohonan SOQUO.pdf");
      // document.body.appendChild(link);
      // link.click();

      return res;
    })
    .catch((err) => {
      dispatch(actionError(EXPORT_REPORT_SOQUO_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const ReportSoquo = { get, exportExcel, exportPdf };
export default ReportSoquo;

export const setReportSoquoData = (data) => (dispatch) =>
  dispatch({ type: REPORT_SOQUO_DATA, data });

export const setReportSoquoModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_REPORT_SOQUO_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
