import React from "react";
import { Route, Redirect } from "react-router-dom";
import { default_url } from "../defaultUrl";
import { getUser } from "../../../utils/user";

const index = ({ component: Component, roles, ...rest }) => {
  if (getUser() === null) {
    return <Redirect to={{ pathname: "/login" }} />;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = getUser();
        if (!currentUser) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        // const {
        //   currentUser: [role_code],
        // } = currentUser;
        // check if route is restricted by role
        if (roles && !roles.includes(currentUser.role_code.toLowerCase())) {
          // role not authorised so redirect to home page
          return (
            <Redirect
              to={{
                pathname: `/${
                  default_url[currentUser.role_code.toLowerCase()]
                }`,
              }}
            />
          );
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default index;
