export const SET_ORDER_MODAL = "SET_ORDER_MODAL";
export const SET_ORDER_DATA = "SET_ORDER_DATA";
export const SET_ORDER_TYPE = "SET_ORDER_TYPE";
export const SET_ORDER_DATA_FILTERED = "SET_ORDER_DATA_FILTERED";
export const SET_ORDER_DATA_SELECTED = "SET_ORDER_DATA_SELECTED";
export const SET_ORDER_PRODUCT_VALUE = "SET_ORDER_PRODUCT_VALUE";
export const SET_EDIT_ORDER_INITIAL_VALUE = "SET_EDIT_ORDER_INITIAL_VALUE";
export const SET_DETAIL_ORDER_DATA = "SET_DETAIL_ORDER_DATA";
export const SET_SELECTED_WAREHOUSE = "SET_SELECTED_WAREHOUSE";
export const SET_REORDER_INITIAL_VALUE = "SET_REORDER_INITIAL_VALUE";
export const SET_DELIVERY_ORDER = "SET_DELIVERY_ORDER";
export const SET_IS_FROM_ORDER = "SET_IS_FROM_ORDER";

export const setOrderModal = (modalType, isOpen) => (dispatch) => {
  dispatch({
    type: SET_ORDER_MODAL,
    data: { modalType: modalType, isOpen: isOpen },
  });
};

export const setOrderData = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDER_DATA,
    data: data,
  });
};

export const setOrderType = (orderType) => (dispatch) => {
  dispatch({
    type: SET_ORDER_TYPE,
    orderType,
  });
};

export const setOrderDataFiltered = (newData) => (dispatch) => {
  dispatch({
    type: SET_ORDER_DATA_FILTERED,
    newData,
  });
};

export const setOrderDataSelected = (selectedData) => (dispatch) => {
  dispatch({
    type: SET_ORDER_DATA_SELECTED,
    selectedData,
  });
};

export const setOrderProductValue = (value) => (dispatch) => {
  dispatch({
    type: SET_ORDER_PRODUCT_VALUE,
    value,
  });
};

export const setEditOrderInitialValue = (value) => (dispatch) => {
  dispatch({
    type: SET_EDIT_ORDER_INITIAL_VALUE,
    value,
  });
};

export const setDetailOrderData = (data) => (dispatch) => {
  dispatch({
    type: SET_DETAIL_ORDER_DATA,
    data,
  });
};

export const setSelectedWarehouse = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_WAREHOUSE,
    data,
  });
};

export const setReorderInitialValue = (value) => (dispatch) => {
  dispatch({
    type: SET_REORDER_INITIAL_VALUE,
    value,
  });
};

export const setDeliveryOrder = (value) => (dispatch) => {
  dispatch({
    type: SET_DELIVERY_ORDER,
    value,
  });
};

export const setIsFromOrder = (value) => (dispatch) => {
  dispatch({
    type: SET_IS_FROM_ORDER,
    value,
  });
};
