import React from "react";

const Index = () => {
  return (
    <div
      className="text-center py-2"
      style={{ backgroundColor: "#ececec" }}
    >
      <label className="m-auto">PT Charoen Pokphand Indonesia © 2021 </label>
    </div>
  );
};

export default Index;
