import React, { useState, useEffect } from "react";
import axios from "axios";
import { reduxForm, Field } from "redux-form";
import { Button, Row, Col } from "reactstrap";
import LoginImage from "../../assets/img/login_page.png";
import CpiLogo from "../../assets/img/logo_app_order_pakan@2x.png";
import { formInput } from "../../components/commons/form";
import { toastSuccess, toastError } from "../../components/commons/toast";
import { default_url } from "../../components/commons/defaultUrl";
import { history } from "../../utils";
import ChangePassword from "../../views/pengaturan/change-password";

// ACTION REDUX
import Auth from "../../store/actions/auth";

import "./login.scss";

let Login = ({ handleSubmit }) => {
  const [isPending, setIsPending] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(true);
  useEffect(() => {
    Auth.logout();
  }, []);

  const onSubmit = ({ username, password }) => {
    setIsPending(true);
    axios
      .post(`${process.env.REACT_APP_SOQUO_FEED_ORDER_URL}/v1/auth/login`, {
        email: username,
        password,
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        //   dispatch(actionSuccess(POST_AUTH_LOGIN_SUCCESS, res));
        setIsPending(false);
        if (res?.data?.data?.is_change_password === 0) {
          localStorage.setItem("user_soquo", JSON.stringify(res.data.data));
          setIsChangePassword(false);
        } else {
          localStorage.setItem("user_soquo", JSON.stringify(res.data.data));
          history.push(
            `/${default_url[res.data.data.role_code.toLowerCase()]}`
          );
          // history.push("/");
          window.location.reload(false);
          toastSuccess(`Selamat Datang ${res.data.data.name}`);
        }
      })
      .catch((err) => {
        setIsPending(false);
        //   dispatch(actionError(POST_AUTH_LOGIN_ERROR));
        toastError(err?.response?.data?.message);
      });
  };
  return (
    <div className="Container d-flex">
      <Col lg={8} md={6} sm={0} className="login-img-container p-0">
        <img src={LoginImage} alt="login-img" className="login-img" />
      </Col>
      <Col lg={4} md={6} sm={12} className="login-container my-auto">
        <img src={CpiLogo} alt="cpi-logo" className=" logo" />
        <div>
          <span className="text-title bold m-0">{`${
            !isChangePassword
              ? "Selamat datang pengguna baru!"
              : isLogin
              ? "Selamat datang kembali!"
              : "Lupa password?"
          }`}</span>
          <span className="text-subtitle d-block">
            {`${
              !isChangePassword
                ? "Silakan mengubah password anda untuk dapat melanjutkan."
                : isLogin
                ? "Silahkan masuk untuk melanjutkan."
                : "Silakan hubungi administrator untuk melakukan reset password login Anda."
            }`}
          </span>
          {isLogin &&
            (isChangePassword ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <>
                  <Field
                    name="username"
                    type="text"
                    placeholder="Email"
                    component={formInput}
                  />
                  <Field
                    name="password"
                    type={!isVisible ? "password" : "text"}
                    placeholder="Password"
                    isVisible={isVisible}
                    setIsVisible={() => setIsVisible(!isVisible)}
                    isTypePassword
                    component={formInput}
                  />
                  <Row className="m-0 justify-content-between align-items-center">
                    <div>
                      <input
                        type="checkbox"
                        name="remember-me"
                        id="remember-me"
                        className="align-middle"
                      />
                      <label className="ml-1 text-small-grey">
                        Tetap masuk
                      </label>
                    </div>
                    <label
                      htmlFor="remember-me"
                      className="forgot-password text-small-grey"
                      onClick={() => setIsLogin(!isLogin)}
                    >
                      Lupa password?
                    </label>
                  </Row>
                </>
                <Button
                  type="submit"
                  color="primary"
                  className="primary-button"
                  disabled={isPending}
                >
                  Masuk
                </Button>
              </form>
            ) : (
              <ChangePassword goBack={() => setIsChangePassword(true)} />
            ))}
          {!isLogin && (
            <Button
              onClick={() => setIsLogin(!isLogin)}
              type="button"
              color="primary"
              className="primary-button"
            >
              Kembali ke Halaman Login
            </Button>
          )}
        </div>
      </Col>
    </div>
  );
};

const validate = ({ username, password }) => {
  const regex_email =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const errors = {};
  if (!username) {
    errors.username = "Email harus diisi";
  } else if (!regex_email.test(username)) {
    errors.username = "Email tidak valid";
  }
  if (!password) {
    errors.password = "Password harus diisi";
  }
  return errors;
};

Login = reduxForm({
  form: "Login",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Login);

export default Login;
