import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_REPORT_DAILY_PENDING = "GET_REPORT_DAILY_PENDING";
export const GET_REPORT_DAILY_SUCCESS = "GET_REPORT_DAILY_SUCCESS";
export const GET_REPORT_DAILY_ERROR = "GET_REPORT_DAILY_ERROR";
export const EXPORT_REPORT_DAILY_PENDING = "EXPORT_REPORT_DAILY_PENDING";
export const EXPORT_REPORT_DAILY_SUCCESS = "EXPORT_REPORT_DAILY_SUCCESS";
export const EXPORT_REPORT_DAILY_ERROR = "EXPORT_REPORT_DAILY_ERROR";

export const REPORT_DAILY_DATA = "REPORT_DAILY_DATA";
export const SET_REPORT_DAILY_MODAL = "SET_REPORT_DAILY_MODAL";

// URL: URL_{URL}
const REPORT_DAILY_URL = "/v1/report/transaction_daily";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPORT_DAILY_PENDING));
  API.get(REPORT_DAILY_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPORT_DAILY_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPORT_DAILY_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const exportExcel = (param) => (dispatch) => {
  dispatch(actionPending(EXPORT_REPORT_DAILY_PENDING));
  API.get(`${REPORT_DAILY_URL}/export_excel`, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(EXPORT_REPORT_DAILY_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Laporan Transaksi Harian.xlsx");
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((err) => {
      dispatch(actionError(EXPORT_REPORT_DAILY_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const ReportDaily = { get, exportExcel };
export default ReportDaily;

export const setReportDailyData = (data) => (dispatch) =>
  dispatch({ type: REPORT_DAILY_DATA, data });

export const setReportDailyModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_REPORT_DAILY_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
