import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import factory from "./master-data/factory";
import area from "./master-data/area";
import city from "./master-data/city";
import region from "./master-data/region";
import product from "./master-data/product";
import user from "./master-data/user";
import customer from "./master-data/customer";
import owner from "./master-data/owner";
import employee from "./master-data/employee";
import supervisor from "./master-data/supervisor";
import global from "./global";
import transaction from "./transaction";
import soquo from "./so-quo";
import allocation from "./master-data/allocation";
import productType from "./master-data/product-type";
import addProduct from "./product";
import order from "./order";
import orderData from "./order-data";
import warehouse from "./customer/warehouse";
import reportTransaction from "./report/transaction";
import reportDaily from "./report/daily";
import reportProduct from "./report/product";
import reportCustomer from "./report/customer";
import reportSoquo from "./report/so-quo";
import auditTrail from "./audit-trail";
import dashboard from "./dashboard";
import notification from "./notification";

const rootReducer = combineReducers({
  form: formReducer,
  auth,
  factory,
  area,
  city,
  region,
  product,
  global,
  user,
  customer,
  owner,
  transaction,
  allocation,
  productType,
  employee,
  supervisor,
  addProduct,
  order,
  orderData,
  warehouse,
  soquo,
  reportTransaction,
  reportDaily,
  reportProduct,
  reportCustomer,
  reportSoquo,
  auditTrail,
  dashboard,
  notification,
});

export default rootReducer;
