import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
import { toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_USER_PENDING = "GET_USER_PENDING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const POST_USER_PENDING = "POST_USER_PENDING";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_ERROR = "POST_USER_ERROR";
export const PUT_USER_PENDING = "PUT_USER_PENDING";
export const PUT_USER_SUCCESS = "PUT_USER_SUCCESS";
export const PUT_USER_ERROR = "PUT_USER_ERROR";
export const DELETE_USER_PENDING = "DELETE_USER_PENDING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const RESET_USER_PASSWORD_PENDING = "RESET_USER_PASSWORD_PENDING";
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS";
export const RESET_USER_PASSWORD_ERROR = "RESET_USER_PASSWORD_ERROR";
export const CHANGE_PASSWORD_USER_PENDING = "CHANGE_PASSWORD_USER_PENDING";
export const CHANGE_PASSWORD_USER_SUCCESS = "CHANGE_PASSWORD_USER_SUCCESS";
export const CHANGE_PASSWORD_USER_ERROR = "CHANGE_PASSWORD_USER_ERROR";

export const USER_DATA = "USER_DATA";
export const SET_USER_MODAL = "SET_USER_MODAL";

// URL: URL_{URL}
const USER_URL = "/v1/user";
const USER_RESET_PASSWORD_URL = "/v1/user/reset_password";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_USER_PENDING));
  API.get(USER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_USER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_USER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_USER_PENDING));
  API.post(USER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_USER_SUCCESS, res));
      // toastSuccess("Pengguna berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Pengguna berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_USER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_USER_PENDING));
  API.put(USER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_USER_SUCCESS, res));
      // toastSuccess("Pengguna berhasil diubah");
      dispatch(setToastModal(true, true, "Pengguna berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_USER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_USER_PENDING));
  API.delete(USER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_USER_SUCCESS, res));
      // toastSuccess("Pengguna berhasil dihapus");
      dispatch(setToastModal(true, true, "Pengguna berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_USER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const resetPassword = (id, callback) => (dispatch) => {
  dispatch(actionPending(RESET_USER_PASSWORD_PENDING));
  const url = `${USER_RESET_PASSWORD_URL}?id=${id}`;
  API.put(url)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(RESET_USER_PASSWORD_SUCCESS, res));
      // toastSuccess("Berhasil mereset password pengguna");
      dispatch(setToastModal(true, true, "Berhasil mereset password pengguna"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(RESET_USER_PASSWORD_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const changePassword = (param, callback) => (dispatch) => {
  dispatch(actionPending(CHANGE_PASSWORD_USER_PENDING));
  API.put(`${USER_URL}/change_password`, param, {
    headers: {
      "x-auth-token": JSON.parse(localStorage.getItem("user_soquo"))?.token,
    },
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(CHANGE_PASSWORD_USER_SUCCESS, res));
      // toastSuccess(
      //   "Password Pengguna berhasil diubah, Silahkan Login menggunakan Password Baru"
      //   );
      dispatch(
        setToastModal(
          true,
          true,
          "Password Pengguna berhasil diubah, Silahkan Login menggunakan Password Baru"
        )
      );
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(CHANGE_PASSWORD_USER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const User = { get, post, put, deleted, resetPassword, changePassword };
export default User;

export const setUserData = (data) => (dispatch) =>
  dispatch({ type: USER_DATA, data });

export const setUserModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_USER_MODAL, { modalType: modalType, isOpen: isOpen })
  );
