const index = ({ value, options, disabled, ...res }) => {
  return options?.map((item) => {
    return (
      <div className="my-2 d-flex align-items-center">
        <input
          {...res}
          type="radio"
          id="deliveryAddress"
          value={item.value}
          disabled={disabled}
        />
        <div className="d-flex flex-column ml-3">
          <label className="m-0 font-weight-bold" for={options.value}>
            {item && item.label}
          </label>
          <span for={options.value}>{item && item.address}</span>
        </div>
      </div>
    );
  });
};

export default index;
