import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_SUPERVISOR_PENDING = "GET_SUPERVISOR_PENDING";
export const GET_SUPERVISOR_SUCCESS = "GET_SUPERVISOR_SUCCESS";
export const GET_SUPERVISOR_ERROR = "GET_SUPERVISOR_ERROR";
export const POST_SUPERVISOR_PENDING = "POST_SUPERVISOR_PENDING";
export const POST_SUPERVISOR_SUCCESS = "POST_SUPERVISOR_SUCCESS";
export const POST_SUPERVISOR_ERROR = "POST_SUPERVISOR_ERROR";
export const PUT_SUPERVISOR_PENDING = "PUT_SUPERVISOR_PENDING";
export const PUT_SUPERVISOR_SUCCESS = "PUT_SUPERVISOR_SUCCESS";
export const PUT_SUPERVISOR_ERROR = "PUT_SUPERVISOR_ERROR";
export const DELETE_SUPERVISOR_PENDING = "DELETE_SUPERVISOR_PENDING";
export const DELETE_SUPERVISOR_SUCCESS = "DELETE_SUPERVISOR_SUCCESS";
export const DELETE_SUPERVISOR_ERROR = "DELETE_SUPERVISOR_ERROR";
export const RESET_SUPERVISOR_PASSWORD_PENDING =
  "RESET_SUPERVISOR_PASSWORD_PENDING";
export const RESET_SUPERVISOR_PASSWORD_SUCCESS =
  "RESET_SUPERVISOR_PASSWORD_SUCCESS";
export const RESET_SUPERVISOR_PASSWORD_ERROR =
  "RESET_SUPERVISOR_PASSWORD_ERROR";

export const SUPERVISOR_DATA = "SUPERVISOR_DATA";
export const SET_SUPERVISOR_MODAL = "SET_SUPERVISOR_MODAL";

// URL: URL_{URL}
const SUPERVISOR_URL = "/v1/user_employee";
const SUPERVISOR_RESET_PASSWORD_URL = "/v1/user_employee/reset_password";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_SUPERVISOR_PENDING));
  API.get(SUPERVISOR_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_SUPERVISOR_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_SUPERVISOR_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_SUPERVISOR_PENDING));
  API.post(SUPERVISOR_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_SUPERVISOR_SUCCESS, res));
      // toastSuccess("Pegawai berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Pegawai berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_SUPERVISOR_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_SUPERVISOR_PENDING));
  API.put(SUPERVISOR_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_SUPERVISOR_SUCCESS, res));
      // toastSuccess("Pegawai berhasil diubah");
      dispatch(setToastModal(true, true, "Pegawai berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_SUPERVISOR_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_SUPERVISOR_PENDING));
  API.delete(SUPERVISOR_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_SUPERVISOR_SUCCESS, res));
      // toastSuccess("Pegawai berhasil dihapus");
      dispatch(setToastModal(true, true, "Pegawai berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_SUPERVISOR_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const resetPassword = (id, callback) => (dispatch) => {
  dispatch(actionPending(RESET_SUPERVISOR_PASSWORD_PENDING));
  const url = `${SUPERVISOR_RESET_PASSWORD_URL}?id=${id}`;
  API.put(url)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(RESET_SUPERVISOR_PASSWORD_SUCCESS, res));
      // toastSuccess("Berhasil mereset password pegawai");
      dispatch(setToastModal(true, true, "Berhasil mereset password pegawai"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(RESET_SUPERVISOR_PASSWORD_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Supervisor = { get, post, put, deleted, resetPassword };
export default Supervisor;

export const setSupervisorData = (data) => (dispatch) =>
  dispatch({ type: SUPERVISOR_DATA, data });

export const setSupervisorModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_SUPERVISOR_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
