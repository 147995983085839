import React from 'react';
import { NavLink } from "react-router-dom";
import { Row } from 'reactstrap';

const ChildMenuItem = ({ link, title, icon }) => {
    const Icon = icon;
    return (
        <NavLink
            to={link}
            className="nav-item"
        >
            <Row className="mr-0 ml-4 mb-2">
                {/* <Icon /> */}
                <span
                    className="title"
                >
                    {title}
                </span>
            </Row>
        </NavLink>
    )
}

export default ChildMenuItem
