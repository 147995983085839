import React, { useState } from "react";
import NumberFormat from "react-number-format";
import SearchIcon from "@material-ui/icons/Search";
import Input from "./input";
import TextArea from "./textarea";
import Select from "./select";
import SecondarySelect from "./select/secondary";
import ThirdSelect from "./select/third";
import Counter from "./input/counter";
import Radio from "./radio";
import File from "./file";
// import RadioSecondary from "./radio/secondary";
import SingleDatePicker from "./datepicker/single-datepicker";
import moment from "moment";

export const formInput = ({
  isDetail,
  input,
  type,
  disabled,
  placeholder,
  rightPlaceholder,
  startIcon,
  endIcon,
  meta,
  defaultValue,
  isVisible,
  isTypePassword,
  setIsVisible,
  maxLength,
}) => {
  return isDetail ? (
    <label>{defaultValue || input.value}</label>
  ) : (
    <div>
      <Input
        {...input}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        startIcon={startIcon}
        endIcon={endIcon}
        rightPlaceholder={rightPlaceholder}
        isError={meta.error}
        isValid={meta.valid}
        isDirty={meta.dirty}
        defaultValue={defaultValue}
        isVisible={isVisible}
        isTypePassword={isTypePassword}
        setIsVisible={setIsVisible}
        maxLength={maxLength}
      />
      <span className="form-validation">{meta.touched && meta.error}</span>
      <div className="mb-3"></div>
    </div>
  );
};

export const formInputCounter = ({
  input,
  type,
  disabled,
  meta,
  width,
  label,
  isDeletable,
  onDelete,
  plus,
  minus,
  defaultValue,
}) => {
  return (
    <div>
      <Counter
        {...input}
        plus={plus}
        minus={minus}
        label={label}
        type={type}
        width={width}
        isDeletable={isDeletable}
        onDelete={onDelete}
        disabled={disabled}
        isError={meta.error}
        isValid={meta.valid}
        defaultValue={defaultValue}
      />
      <span className="form-validation">{meta.touched && meta.error}</span>
      <div className="mb-3"></div>
    </div>
  );
};

export const formInputNumber = ({
  isDetail,
  input,
  disabled,
  placeholder,
  rightPlaceholder,
  thousandSeparator,
  prefix,
  meta,
}) => {
  return isDetail ? (
    input.value
  ) : (
    <div>
      <NumberFormat
        {...input}
        customInput={Input}
        thousandSeparator={thousandSeparator ? "." : false}
        decimalSeparator={thousandSeparator ? "," : "."}
        disabled={disabled}
        placeholder={placeholder}
        rightPlaceholder={rightPlaceholder}
        prefix={prefix}
        isError={meta.error}
        isValid={meta.valid}
      />
      <span className="form-validation">{meta.touched && meta.error}</span>
      <div className="mb-3"></div>
    </div>
  );
};

export const formTextArea = ({
  isDetail,
  input,
  disabled,
  placeholder,
  startIcon,
  endIcon,
  meta,
  rows,
}) => {
  return isDetail ? (
    input.value
  ) : (
    <div>
      <TextArea
        {...input}
        rows={rows}
        disabled={disabled}
        placeholder={placeholder}
        startIcon={startIcon}
        endIcon={endIcon}
        isError={meta.error}
        isValid={meta.valid}
      />
      <span className="form-validation">{meta.touched && meta.error}</span>
      <div className="mb-3"></div>
    </div>
  );
};

// Masih harus diperbaiki untuk onchange uncheck
export const FormCheckBox = ({
  isDetail,
  input,
  options,
  disabled,
  placeholder,
  startIcon,
  endIcon,
  meta,
}) => {
  const [productList, setProductList] = useState(options);
  const [checkedItems, setCheckedItems] = useState(new Map());

  const handleCheckBox = (e) => {
    let isChecked = e.target.checked;
    let item = e.target.value;

    if (isChecked === true) {
      setCheckedItems((prevState) => {
        return new Map(prevState).set(item, isChecked);
      });
    } else {
      setCheckedItems((prevState) => {
        return new Map(prevState).delete(item);
      });
    }
  };

  return isDetail ? (
    input.value
  ) : (
    <div className="d-flex flex-column mt-2 align-items-stretch">
      <div className="input-container">
        <input
          value={`${checkedItems.size} produk dipilih`}
          disabled
          className="input-component"
        />
        <label className="placeholder m-0">Produk yang dijual</label>
      </div>
      <div className="d-flex flex-column border rounded overflow-auto pb-3 align-items-stretch">
        <div className="d-flex justify-content-end py-3 pr-4">
          <Input placeholder="Cari produk" hasBorder endIcon={SearchIcon} />
        </div>
        <div className="form-check">
          {productList.map((item) => (
            <div className="ml-3">
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id={item.id}
                onChange={(e) => handleCheckBox(e)}
              />
              <label className="form-check-label" htmlFor={item.id}>
                {item.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const formSelect = ({
  isDetail,
  input,
  options,
  disabled,
  placeholder,
  isSearchable,
  isClearable,
  isMulti,
  isAsync,
  asyncUrl,
  meta,
  param,
}) => {
  const handleBlur = () => {
    // setTimeout(() => {
    //   input.onBlur(input.value);
    // }, 1);
  };
  return isDetail ? (
    input.value
  ) : (
    <div>
      <Select
        {...input}
        onChange={(value) => input.onChange(value)}
        placeholder={input.placeholder}
        onBlur={handleBlur}
        options={options}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMulti}
        isDisabled={disabled}
        isAsync={isAsync}
        asyncUrl={asyncUrl}
        isError={meta.error}
        param={param}
      />
      <span className="form-validation">{meta.touched && meta.error}</span>
      <div className="mb-3"></div>
    </div>
  );
};

export const formSecondarySelect = ({
  input,
  label,
  options,
  width,
  disabled,
  defaultValue,
}) => {
  return (
    <SecondarySelect
      {...input}
      onChange={(value) => input.onChange(value)}
      label={label}
      options={options}
      width={width}
      disabled={disabled}
      defaultValue={defaultValue}
    />
  );
};

export const formRadio = ({ input, options, disabled, meta }) => {
  return (
    <>
      <Radio
        {...input}
        options={options}
        disabled={disabled}
        name={input.name}
      />
      <span className="form-validation">{meta.touched && meta.error}</span>
      <div className="mb-3"></div>
    </>
  );
};

// export const formRadioSecondary = ({
//   input,
//   options,
//   disabled,
//   placeholder,
// }) => {
//   return (
//     <RadioSecondary
//       {...input}
//       options={options}
//       name={input.name}
//       disabled={disabled}
//       placeholder={placeholder}
//     />
//   );
// };

export const formSelectThird = ({
  isDetail,
  input,
  options,
  disabled,
  placeholder,
  isSearchable,
  isClearable,
  isMulti,
  isAsync,
  customLabel,
  asyncUrl,
  meta,
  param,
}) => {
  const handleBlur = () => {
    setTimeout(() => {
      input.onBlur(input.value);
    }, 1);
  };
  return isDetail ? (
    input.value
  ) : (
    <div>
      <ThirdSelect
        {...input}
        onChange={(value) => input.onChange(value)}
        placeholder={input.placeholder}
        onBlur={handleBlur}
        options={options}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMulti}
        customLabel={customLabel}
        isDisabled={disabled}
        isAsync={isAsync}
        asyncUrl={asyncUrl}
        isError={meta.error}
        param={param}
      />
      <span className="form-validation">{meta.touched && meta.error}</span>
      <div className="mb-3"></div>
    </div>
  );
};

export const FormDatepicker = ({
  input,
  disabled,
  placeholder,
  meta,
  format,
}) => {
  const [focused, setFocused] = useState(false);
  const onFocusChange = (value) => {
    setFocused(!focused);
    input.onFocus(value);
  };
  return (
    <div>
      <SingleDatePicker
        format={format}
        disabled={disabled}
        placeholder={placeholder}
        date={input.value}
        onDateChange={input.onChange}
        focused={focused}
        onFocusChange={onFocusChange}
        id={input.name}
        small
        block
        noBorder
      />
      <span className="form-validation">{meta.touched && meta.error}</span>
      <div className="mb-3"></div>
    </div>
  );
};

export const formatDates = (value) => (value ? moment(value) : null);

export const normalizeDates = (value) =>
  value ? value.format("YYYY-MM-DD") : null;

// export const formatNumber = (value) => (value ? value.toString() : "");

// export const normalizeNumber = (value) =>
//   value ? value.toString().replace(/\D/g, "") : "0";

export function FormFile({
  filename,
  filePath,
  input,
  meta,
  fileType,
  isDetail,
  placeholder,
}) {
  const [rawFile, setRawFile] = useState();
  const { onChange, value } = input;
  const change = (e) => {
    setRawFile(e.target.files);
    onChange(e.target.files[0]);
  };
  return (
    <>
      {isDetail ? (
        <div className="mb-3">
          {!filename ? (
            "-"
          ) : (
            <a
              href={`${process.env.REACT_APP_SOQUO_FEED_ORDER_URL}/v1/file/${filePath}/${filename}`}
              style={{ textDecoration: "none" }}
              taget="_blank"
            >
              Download File
            </a>
          )}
        </div>
      ) : (
        <div>
          <File
            {...input}
            type="file"
            fileType={fileType}
            onChange={(e) => change(e)}
            value={value}
            rawFile={rawFile}
            placeholder={placeholder}
          />
          <span className="form-validation">{meta.touched && meta.error}</span>
          <div className="mb-3"></div>
        </div>
      )}
    </>
  );
}

export const formCheckbox = ({ input, label, meta: { touched, error } }) => (
  <div>
    <input {...input} type="checkbox" defaultChecked={input.value} />
    <label htmlFor={input.name} className="mx-2">
      {label}
    </label>
    <div>
      <span className="form-validation">{touched && error}</span>
    </div>
  </div>
);
