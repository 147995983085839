import React from "react";

export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={props.opacity || ""}
      d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"
      fill={props.fill || "#FFFFFF"}
      transform="translate(6,1)"
    />
  </svg>
);
