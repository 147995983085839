import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_ALLOCATION_PENDING = "GET_ALLOCATION_PENDING";
export const GET_ALLOCATION_SUCCESS = "GET_ALLOCATION_SUCCESS";
export const GET_ALLOCATION_ERROR = "GET_ALLOCATION_ERROR";

export const ALLOCATION_DATA = "ALLOCATION_DATA";
export const SET_ALLOCATION_MODAL = "SET_ALLOCATION_MODAL";

// URL: URL_{URL}
const ALLOCATION_URL = "/v1/allocation";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_ALLOCATION_PENDING));
  API.get(ALLOCATION_URL, {
    params: param,
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ALLOCATION_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ALLOCATION_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Allocation = { get };
export default Allocation;
