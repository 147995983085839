import {
    GET_ALLOCATION_PENDING,
    GET_ALLOCATION_SUCCESS,
    GET_ALLOCATION_ERROR,
  } from "../../../actions/master-data/allocation";
  
  const initialState = {
    pending: false,
    error: null,
    data: null,
    detailData: null,
    isOpenModal: false,
    modalType: "",
  };
  
  const allocation = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALLOCATION_PENDING:
        return {
          ...state,
          pending: true,
        };
      case GET_ALLOCATION_SUCCESS:
        return {
          ...state,
          pending: false,
          data: action.data,
        };
      case GET_ALLOCATION_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default allocation;
  