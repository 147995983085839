import { Col, Row } from "reactstrap";
import NumberFormat from "react-number-format";
import "../dashboard.scss";

function Index({ icon, label, value }) {
  const Icon = icon;
  return (
    <Row className="m-0 d-flex counter-container">
      <div className="icon-container">
        <Icon className="icon" />
      </div>
      <Col className="d-flex flex-column align-self-center">
        {label}
        <label className="counter-number">
          <NumberFormat
            thousandSeparator="."
            decimalSeparator=","
            displayType={"text"}
            value={value}
          />
        </label>
      </Col>
    </Row>
  );
}

export default Index;
