const secondary = ({
  value,
  onChange,
  label,
  options,
  disabled,
  width,
  defaultValue,
}) => {
  return (
    <div className={`d-flex ${label && "justify-content-between"}`}>
      <label>{label}</label>
      <select
        type="select"
        value={defaultValue ? defaultValue.toString() : value}
        disabled={disabled}
        onChange={onChange}
        options={options}
        style={{ width: width }}
        className="secondary-select"
      >
        {options.map((item, idx) => {
          return (
            <option key={idx} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default secondary;
