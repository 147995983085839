import { history } from "../../../utils";
import axios from "axios";

// import API from "../API";
import { toastSuccess, toastError } from "../../../components/commons/toast";
import { getUser } from "../../../utils/user";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const POST_AUTH_LOGIN_PENDING = "POST_AUTH_LOGIN_PENDING";
export const POST_AUTH_LOGIN_SUCCESS = "POST_AUTH_LOGIN_SUCCESS";
export const POST_AUTH_LOGIN_ERROR = "POST_AUTH_LOGIN_ERROR";

// URL: URL_{URL}
const AUTH_LOGIN_URL = `${process.env.REACT_APP_SOQUO_FEED_ORDER_URL}/v1/auth/login`;

const auth_login = (param) => {
  //   dispatch(actionPending(POST_AUTH_LOGIN_PENDING));
  axios
    .post(AUTH_LOGIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      //   dispatch(actionSuccess(POST_AUTH_LOGIN_SUCCESS, res));
      localStorage.setItem("user_soquo", JSON.stringify(res.data.data));
      history.push(`/${res.data.data.role_code.toLowerCase()}`);
      // history.push("/");
      window.location.reload(false);
      toastSuccess(`Selamat Datang ${res.data.data.name}`);
    })
    .catch((err) => {
      //   dispatch(actionError(POST_AUTH_LOGIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const logout = () => {
  if (getUser() && getUser() !== null) {
    localStorage.removeItem("user_soquo");
    localStorage.removeItem("persist:root");
    history.push("/login");
  }
};

const Auth = { auth_login, logout };
export default Auth;
