import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { formInput } from "../../../components/commons/form";
import { Button } from "reactstrap";
import User from "../../../store/actions/master-data/user";
import Employee from "../../../store/actions/master-data/employee";
import Customer from "../../../store/actions/master-data/customer";
import { history } from "../../../utils";
import { getUser } from "../../../utils/user";
import { regexPassword } from "../../../utils/constant";

let ChangePassword = ({ handleSubmit, goBack, pending }) => {
  const dispatch = useDispatch();
  const { role_code } = getUser();
  const [isVisibleOld, setIsVisibleOld] = useState(false);
  const [isVisibleNew, setIsVisibleNew] = useState(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);

  const onSubmit = ({ old_password, new_password, confirm_new_password }) => {
    const param = {
      old_password,
      new_password,
      confirm_new_password,
    };
    const callback = () => {
      if (history?.location?.pathname === "/login") {
        goBack();
      }
      history.push("/login");
    };
    dispatch(User.changePassword(param, callback));
  };
  return (
    <div className="mt-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="old_password"
          placeholder="Password Lama"
          type={!isVisibleOld ? "password" : "text"}
          isVisible={isVisibleOld}
          setIsVisible={() => setIsVisibleOld(!isVisibleOld)}
          isTypePassword
          component={formInput}
        />
        <Field
          name="new_password"
          placeholder="Password Baru"
          type={!isVisibleNew ? "password" : "text"}
          isVisible={isVisibleNew}
          setIsVisible={() => setIsVisibleNew(!isVisibleNew)}
          isTypePassword
          component={formInput}
        />
        <Field
          name="confirm_new_password"
          placeholder="Konfirmasi Password Baru"
          type={!isVisibleConfirm ? "password" : "text"}
          isVisible={isVisibleConfirm}
          setIsVisible={() => setIsVisibleConfirm(!isVisibleConfirm)}
          isTypePassword
          component={formInput}
        />
        <div className="d-flex justify-content-between">
          <Button
            outline
            className="mt-3"
            color="primary"
            onClick={goBack}
            style={{ padding: "15px 40px" }}
          >
            Batal
          </Button>
          <Button
            type="submit"
            className="mt-3"
            color="primary"
            disabled={pending}
            style={{ padding: "15px 40px" }}
          >
            Simpan
          </Button>
        </div>
      </form>
    </div>
  );
};

const validate = ({ old_password, new_password, confirm_new_password }) => {
  const errors = {};
  if (!old_password) {
    errors.old_password = "Password Lama harus diisi";
  }
  if (!new_password) {
    errors.new_password = "Password Baru harus diisi";
  } else if (new_password && !regexPassword.test(new_password)) {
    errors.new_password = "Password harus lebih dari 8 huruf dan angka";
  }
  if (!confirm_new_password) {
    errors.confirm_new_password = "Konfirmasi Password Baru harus diisi";
  } else if (
    confirm_new_password &&
    !regexPassword.test(confirm_new_password)
  ) {
    errors.confirm_new_password = "Password harus lebih dari 8 huruf";
  } else if (confirm_new_password !== new_password) {
    errors.confirm_new_password =
      "Konfirmasi Password Baru tidak sama dengan Password Baru";
  }

  return errors;
};

ChangePassword = reduxForm({
  form: "ChangePassword",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(ChangePassword);

const mapStateToProps = ({ user: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
