import {
  GET_SOQUO_PENDING,
  GET_SOQUO_SUCCESS,
  GET_SOQUO_ERROR,
  POST_SOQUO_PENDING,
  POST_SOQUO_SUCCESS,
  POST_SOQUO_ERROR,
  PUT_SOQUO_PENDING,
  PUT_SOQUO_SUCCESS,
  PUT_SOQUO_ERROR,
  DELETE_SOQUO_PENDING,
  DELETE_SOQUO_SUCCESS,
  DELETE_SOQUO_ERROR,
  APPROVAL_SOQUO_PENDING,
  APPROVAL_SOQUO_SUCCESS,
  APPROVAL_SOQUO_ERROR,
  SOQUO_DATA,
  SET_SOQUO_MODAL,
  SET_ADD_SOQUO_STEP,
  SET_ADD_SOQUO_DATA,
} from "../../actions/so-quo";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
  step: 1,
};

const soquo = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOQUO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_SOQUO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_SOQUO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_SOQUO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_SOQUO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_SOQUO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_SOQUO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_SOQUO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_SOQUO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_SOQUO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_SOQUO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_SOQUO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case APPROVAL_SOQUO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVAL_SOQUO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case APPROVAL_SOQUO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SOQUO_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_SOQUO_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    case SET_ADD_SOQUO_STEP:
      return {
        ...state,
        step: action.step,
      };
    case SET_ADD_SOQUO_DATA:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export default soquo;
