import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import { func } from "prop-types";

const index = ({
  value,
  placeholder,
  disabled,
  onChange,
  onClick,
  startIcon,
  endIcon,
  isError,
  isValid,
  hasBorder,
  rows,
}) => {
  const StartIcon = startIcon;
  const EndIcon = endIcon;
  return (
    <div className="textarea-container">
      <textarea
        rows={rows ? rows : 5}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={hasBorder && placeholder}
        className={`${
          hasBorder ? "textarea-component-border" : "textarea-component"
        } ${isError && "textarea-error"} ${!isValid && "textarea-invalid"}`}
      />
      <label className="placeholder m-0">{placeholder}</label>
      {startIcon && <StartIcon onClick={onClick} />}
      {endIcon && <EndIcon className="textarea-end-icon" />}
    </div>
  );
};

index.propTypes = {};

export default index;
