import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_CUSTOMER_PENDING = "GET_CUSTOMER_PENDING";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_ERROR = "GET_CUSTOMER_ERROR";
export const POST_CUSTOMER_PENDING = "POST_CUSTOMER_PENDING";
export const POST_CUSTOMER_SUCCESS = "POST_CUSTOMER_SUCCESS";
export const POST_CUSTOMER_ERROR = "POST_CUSTOMER_ERROR";
export const PUT_CUSTOMER_PENDING = "PUT_CUSTOMER_PENDING";
export const PUT_CUSTOMER_SUCCESS = "PUT_CUSTOMER_SUCCESS";
export const PUT_CUSTOMER_ERROR = "PUT_CUSTOMER_ERROR";
export const DELETE_CUSTOMER_PENDING = "DELETE_CUSTOMER_PENDING";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_ERROR = "DELETE_CUSTOMER_ERROR";
export const RESET_CUSTOMER_PASSWORD_PENDING =
  "RESET_CUSTOMER_PASSWORD_PENDING";
export const RESET_CUSTOMER_PASSWORD_SUCCESS =
  "RESET_CUSTOMER_PASSWORD_SUCCESS";
export const RESET_CUSTOMER_PASSWORD_ERROR = "RESET_CUSTOMER_PASSWORD_ERROR";
export const CHANGE_PASSWORD_CUSTOMER_PENDING =
  "CHANGE_PASSWORD_CUSTOMER_PENDING";
export const CHANGE_PASSWORD_CUSTOMER_SUCCESS =
  "CHANGE_PASSWORD_CUSTOMER_SUCCESS";
export const CHANGE_PASSWORD_CUSTOMER_ERROR = "CHANGE_PASSWORD_CUSTOMER_ERROR";

export const CUSTOMER_DATA = "CUSTOMER_DATA";
export const SET_CUSTOMER_MODAL = "SET_CUSTOMER_MODAL";

// URL: URL_{URL}
const CUSTOMER_URL = "/v1/customer";
const CUSTOMER_RESET_PASSWORD_URL = "/v1/customer/reset_password";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_CUSTOMER_PENDING));
  API.get(CUSTOMER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_CUSTOMER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_CUSTOMER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_CUSTOMER_PENDING));
  API.post(CUSTOMER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_CUSTOMER_SUCCESS, res));
      // toastSuccess("Customer berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Customer berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_CUSTOMER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_CUSTOMER_PENDING));
  API.put(CUSTOMER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_CUSTOMER_SUCCESS, res));
      // toastSuccess("Customer berhasil diubah");
      dispatch(setToastModal(true, true, "Customer berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_CUSTOMER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_CUSTOMER_PENDING));
  API.delete(CUSTOMER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_CUSTOMER_SUCCESS, res));
      // toastSuccess("Customer berhasil dihapus");
      dispatch(setToastModal(true, true, "Customer berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_CUSTOMER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const resetPassword = (id, callback) => (dispatch) => {
  dispatch(actionPending(RESET_CUSTOMER_PASSWORD_PENDING));
  const url = `${CUSTOMER_RESET_PASSWORD_URL}?id=${id}`;
  API.put(url)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(RESET_CUSTOMER_PASSWORD_SUCCESS, res));
      // toastSuccess("Berhasil mereset password customer");
      dispatch(setToastModal(true, true, "Berhasil mereset password customer"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(RESET_CUSTOMER_PASSWORD_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const changePassword = (param, callback) => (dispatch) => {
  dispatch(actionPending(CHANGE_PASSWORD_CUSTOMER_PENDING));
  API.post(`${CUSTOMER_URL}/change_password`, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(CHANGE_PASSWORD_CUSTOMER_SUCCESS, res));
      // toastSuccess("Password Customer berhasil diubah");
      dispatch(setToastModal(true, true, "Password Customer berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(CHANGE_PASSWORD_CUSTOMER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Customer = { get, post, put, deleted, resetPassword, changePassword };
export default Customer;

export const setCustomerData = (data) => (dispatch) =>
  dispatch({ type: CUSTOMER_DATA, data });

export const setCustomerModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_CUSTOMER_MODAL, { modalType: modalType, isOpen: isOpen })
  );
