import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_OWNER_PENDING = "GET_OWNER_PENDING";
export const GET_OWNER_SUCCESS = "GET_OWNER_SUCCESS";
export const GET_OWNER_ERROR = "GET_OWNER_ERROR";
export const POST_OWNER_PENDING = "POST_OWNER_PENDING";
export const POST_OWNER_SUCCESS = "POST_OWNER_SUCCESS";
export const POST_OWNER_ERROR = "POST_OWNER_ERROR";
export const PUT_OWNER_PENDING = "PUT_OWNER_PENDING";
export const PUT_OWNER_SUCCESS = "PUT_OWNER_SUCCESS";
export const PUT_OWNER_ERROR = "PUT_OWNER_ERROR";
export const DELETE_OWNER_PENDING = "DELETE_OWNER_PENDING";
export const DELETE_OWNER_SUCCESS = "DELETE_OWNER_SUCCESS";
export const DELETE_OWNER_ERROR = "DELETE_OWNER_ERROR";

export const OWNER_DATA = "OWNER_DATA";
export const SET_OWNER_MODAL = "SET_OWNER_MODAL";

// URL: URL_{URL}
const OWNER_URL = "/v1/owner";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_OWNER_PENDING));
  API.get(OWNER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_OWNER_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_OWNER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_OWNER_PENDING));
  API.post(OWNER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_OWNER_SUCCESS, res));
      // toastSuccess("Owner berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Owner berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_OWNER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_OWNER_PENDING));
  API.put(OWNER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_OWNER_SUCCESS, res));
      // toastSuccess("Owner berhasil diubah");
      dispatch(setToastModal(true, true, "Owner berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_OWNER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_OWNER_PENDING));
  API.delete(OWNER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_OWNER_SUCCESS, res));
      // toastSuccess("Owner berhasil dihapus");
      dispatch(setToastModal(true, true, "Owner berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_OWNER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Owner = { get, post, put, deleted };
export default Owner;

export const setOwnerData = (data) => (dispatch) =>
  dispatch({ type: OWNER_DATA, data });

export const setOwnerModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_OWNER_MODAL, { modalType: modalType, isOpen: isOpen })
  );
