import {
  SET_ADD_PRODUCT_STEP,
  SET_ADD_PRODUCT_DATA,
} from "../../actions/product";

const initialState = {
  step: 1,
  data: null,
};

const addProduct = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADD_PRODUCT_STEP:
      return {
        ...state,
        step: action.step,
      };
    case SET_ADD_PRODUCT_DATA:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export default addProduct;
