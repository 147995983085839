import API from "../API";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
// import { toastSuccess, toastError } from "../../../components/commons/toast";
import getToken from "../../../utils/token";
import { setToastModal } from "../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_TRANSACTION_PENDING = "GET_TRANSACTION_PENDING";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_ERROR = "GET_TRANSACTION_ERROR";
export const POST_TRANSACTION_PENDING = "POST_TRANSACTION_PENDING";
export const POST_TRANSACTION_SUCCESS = "POST_TRANSACTION_SUCCESS";
export const POST_TRANSACTION_ERROR = "POST_TRANSACTION_ERROR";
export const PUT_TRANSACTION_PENDING = "PUT_TRANSACTION_PENDING";
export const PUT_TRANSACTION_SUCCESS = "PUT_TRANSACTION_SUCCESS";
export const PUT_TRANSACTION_ERROR = "PUT_TRANSACTION_ERROR";
export const DELETE_TRANSACTION_PENDING = "DELETE_TRANSACTION_PENDING";
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
export const DELETE_TRANSACTION_ERROR = "DELETE_TRANSACTION_ERROR";
export const LEVEL_UP_STATUS_TRANSACTION_PENDING =
  "LEVEL_UP_STATUS_TRANSACTION_PENDING";
export const LEVEL_UP_STATUS_TRANSACTION_SUCCESS =
  "LEVEL_UP_STATUS_TRANSACTION_SUCCESS";
export const LEVEL_UP_STATUS_TRANSACTION_ERROR =
  "LEVEL_UP_STATUS_TRANSACTION_ERROR";
export const EXPORT_TRANSACTION_PENDING = "EXPORT_TRANSACTION_PENDING";
export const EXPORT_TRANSACTION_SUCCESS = "EXPORT_TRANSACTION_SUCCESS";
export const EXPORT_TRANSACTION_ERROR = "EXPORT_TRANSACTION_ERROR";

export const TRANSACTION_DATA = "TRANSACTION_DATA";
export const SET_TRANSACTION_MODAL = "SET_TRANSACTION_MODAL";

// URL: URL_{URL}
const TRANSACTION_URL = "/v1/order";
const TRANSACTION_LEVEL_UP_STATUS_URL = "v1/order/level_up_status";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_TRANSACTION_PENDING));
  API.get(TRANSACTION_URL, { params: param, headers: getToken()?.headers })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_TRANSACTION_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_TRANSACTION_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_TRANSACTION_PENDING));
  API.post(TRANSACTION_URL, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_TRANSACTION_SUCCESS, res));
      // toastSuccess("Transaksi berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Transaksi berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_TRANSACTION_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_TRANSACTION_PENDING));
  API.put(TRANSACTION_URL, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_TRANSACTION_SUCCESS, res));
      // toastSuccess("Transaksi berhasil diubah");
      dispatch(setToastModal(true, true, "Transaksi berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_TRANSACTION_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_TRANSACTION_PENDING));
  API.delete(TRANSACTION_URL, { params: param }, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_TRANSACTION_SUCCESS, res));
      // toastSuccess("Transaksi berhasil dihapus");
      dispatch(setToastModal(true, true, "Transaksi berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_TRANSACTION_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const levelUpStatus = (param, callback) => (dispatch) => {
  dispatch(actionPending(LEVEL_UP_STATUS_TRANSACTION_PENDING));
  API.put(TRANSACTION_LEVEL_UP_STATUS_URL, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(LEVEL_UP_STATUS_TRANSACTION_SUCCESS, res));
      // toastSuccess(
      //   res.data.data.status === 2
      //   ? "Pesanan Berhasil Disetujui"
      //   : "Pesanan Berhasil Dikirim"
      //   );
      dispatch(
        setToastModal(
          true,
          true,
          res.data.data.status === 2
            ? "Pesanan Berhasil Disetujui"
            : "Pesanan Berhasil Dikirim"
        )
      );
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(LEVEL_UP_STATUS_TRANSACTION_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const exportPdf = (param) => (dispatch) => {
  dispatch(actionPending(EXPORT_TRANSACTION_PENDING));
  API.get(`${TRANSACTION_URL}/print_po`, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(EXPORT_TRANSACTION_SUCCESS, res));
      // const url = window.URL.createObjectURL(new Blob([res.data]));
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", "Laporan Permohonan SOQUO.pdf");
      // document.body.appendChild(link);
      // link.click();

      return res;
    })
    .catch((err) => {
      dispatch(actionError(EXPORT_TRANSACTION_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Transaction = { get, post, put, deleted, levelUpStatus, exportPdf };
export default Transaction;

export const setTransactionData = (data) => (dispatch) =>
  dispatch({ type: TRANSACTION_DATA, data });

export const setTransactionModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_TRANSACTION_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
