import API from "../API";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
// import { toastError } from "../../../components/commons/toast";
import { setToastModal } from "../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_DASHBOARD_PENDING = "GET_DASHBOARD_PENDING";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";

// URL: URL_{URL}
const DASHBOARD_URL = "/v1/dashboard";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_PENDING));
  API.get(DASHBOARD_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_ERROR));
      // toastError(err.response?.data.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Dashboard = { get };
export default Dashboard;
