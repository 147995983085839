import API from "../API";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
// import { toastError } from "../../../components/commons/toast";
import getToken from "../../../utils/token";
import { setToastModal } from "../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_AUDIT_TRAIL_PENDING = "GET_AUDIT_TRAIL_PENDING";
export const GET_AUDIT_TRAIL_SUCCESS = "GET_AUDIT_TRAIL_SUCCESS";
export const GET_AUDIT_TRAIL_ERROR = "GET_AUDIT_TRAIL_ERROR";
export const EXPORT_AUDIT_TRAIL_PENDING = "EXPORT_AUDIT_TRAIL_PENDING";
export const EXPORT_AUDIT_TRAIL_SUCCESS = "EXPORT_AUDIT_TRAIL_SUCCESS";
export const EXPORT_AUDIT_TRAIL_ERROR = "EXPORT_AUDIT_TRAIL_ERROR";

export const SET_AUDIT_TRAIL_DATA = "SET_AUDIT_TRAIL_DATA";
export const SET_AUDIT_TRAIL_MODAL = "SET_AUDIT_TRAIL_MODAL";

// URL: URL_{URL}
const AUDIT_TRAIL_URL = "/v1/audit_trail";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_AUDIT_TRAIL_PENDING));
  API.get(AUDIT_TRAIL_URL, {
    params: param,
    headers: getToken()?.headers,
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_AUDIT_TRAIL_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_AUDIT_TRAIL_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const exportExcel = (param) => (dispatch) => {
  dispatch(actionPending(EXPORT_AUDIT_TRAIL_PENDING));
  API.get(`${AUDIT_TRAIL_URL}/export_excel`, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(EXPORT_AUDIT_TRAIL_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Audit Trail.xlsx");
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((err) => {
      dispatch(actionError(EXPORT_AUDIT_TRAIL_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const auditTrail = { get, exportExcel };
export default auditTrail;

export const setAuditTrailData = (data) => (dispatch) =>
  dispatch({ type: SET_AUDIT_TRAIL_DATA, data });

export const setAuditTrailModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_AUDIT_TRAIL_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
