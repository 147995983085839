export const default_url = {
  superadmin: "dashboard",
  customer: "order",
  administrator: "dashboard",
  finance: "dashboard",
  sales_admin: "dashboard",
  sales_team: "dashboard",
  technical_support: "dashboard",
  gm: "dashboard",
  avp: "dashboard",
  rh: "dashboard",
};
