import {
  GET_CITY_PENDING,
  GET_CITY_SUCCESS,
  GET_CITY_ERROR,
  POST_CITY_PENDING,
  POST_CITY_SUCCESS,
  POST_CITY_ERROR,
  PUT_CITY_PENDING,
  PUT_CITY_SUCCESS,
  PUT_CITY_ERROR,
  DELETE_CITY_PENDING,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_ERROR,
  CITY_DATA,
  SET_CITY_MODAL,
} from "../../../actions/master-data/city";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const city = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_CITY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_CITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_CITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_CITY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_CITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_CITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_CITY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_CITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_CITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_CITY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_CITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case CITY_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_CITY_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default city;
