import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_CITY_PENDING = "GET_CITY_PENDING";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_ERROR = "GET_CITY_ERROR";
export const POST_CITY_PENDING = "POST_CITY_PENDING";
export const POST_CITY_SUCCESS = "POST_CITY_SUCCESS";
export const POST_CITY_ERROR = "POST_CITY_ERROR";
export const PUT_CITY_PENDING = "PUT_CITY_PENDING";
export const PUT_CITY_SUCCESS = "PUT_CITY_SUCCESS";
export const PUT_CITY_ERROR = "PUT_CITY_ERROR";
export const DELETE_CITY_PENDING = "DELETE_CITY_PENDING";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_ERROR = "DELETE_CITY_ERROR";

export const CITY_DATA = "CITY_DATA";
export const SET_CITY_MODAL = "SET_CITY_MODAL";

// URL: URL_{URL}
const CITY_URL = "/v1/city";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_CITY_PENDING));
  API.get(CITY_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_CITY_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_CITY_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_CITY_PENDING));
  API.post(CITY_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_CITY_SUCCESS, res));
      // toastSuccess("Kota berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Kota berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_CITY_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_CITY_PENDING));
  API.put(CITY_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_CITY_SUCCESS, res));
      // toastSuccess("Kota berhasil diubah");
      dispatch(setToastModal(true, true, "Kota berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_CITY_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_CITY_PENDING));
  API.delete(CITY_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_CITY_SUCCESS, res));
      // toastSuccess("Kota berhasil dihapus");
      dispatch(setToastModal(true, true, "Kota berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_CITY_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const City = { get, post, put, deleted };
export default City;

export const setCityData = (data) => (dispatch) =>
  dispatch({ type: CITY_DATA, data });

export const setCityModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_CITY_MODAL, { modalType: modalType, isOpen: isOpen })
  );
