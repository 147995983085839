import React from "react";
import { bool } from "prop-types";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";
import API from "../../../../store/actions/API";
import getToken from "../../../../utils/token";
const { ValueContainer, Placeholder } = components;

const custom = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props}>
        {props.selectProps.inputValue || props.hasValue ? (
          <span
            style={{
              fontSize: 12,
              whiteSpace: "nowrap",
            }}
          >
            {props.selectProps.placeholder}
          </span>
        ) : (
          props.selectProps.placeholder
        )}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const colourStyles = {
  control: (style, state) => {
    const borderColor = state.selectProps.isError ? "#ff1b1b" : "#ececec";
    return {
      ...style,
      borderWidth: "0 0 1px 0",
      borderRadius: 0,
      borderColor: state.isFocused
        ? "#1c37be"
        : state.hasValue
        ? "#1c37be"
        : state.selectProps.isError
        ? "#ff1b1b"
        : "#DDDDDD",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: "#1c37be",
      },
      fontSize: 12,
      width: "100%",
      height: "100%",
    };
  },
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    overflow: "visible",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    fontSize: 12,
    zIndex: 999,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  input: (styles) => {
    return {
      ...styles,
      "& input": {
        font: "inherit",
      },
    };
  },
  placeholder: (styles, state) => ({
    ...styles,
    fontSize: 14,
    color: "#495057",
    opacity: 0.68,
    transform: state.isFocused
      ? "translateY(-20px)"
      : state.hasValue
      ? "translateY(-20px)"
      : "none",
    position: "absolute",
    top: 5,
    left: 0,
    pointerEvents: "none",
    transition: "all 0.3s ease",
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 12,
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#FFFFFF",
      // borderRadius: "21px",
      height: 30,
      marginLeft: 0,
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: 12,
    paddingLeft: 0,
    paddingTop: 6,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#ababab",
    "&:hover": {
      cursor: "pointer",
    },
  }),
};

const SelectComponent = (props) => {
  const {
    asyncUrl,
    isAsync,
    options,
    isClearable,
    placeholder,
    customLabel,
    param = {},
  } = props;
  const delayedQuery = debounce((inputValue, callback) => {
    param.search_text = inputValue;
    API.get(asyncUrl, { params: param }).then((res) => {
      if (customLabel) {
        callback(
          res.data.data.map((item) => ({
            label: `${item.code} (${item.name})`,
            value: item.id,
          }))
        );
      } else {
        callback(
          res.data.data.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
      }
    });
  }, 500);

  const loadOptions = (inputValue, callback) => {
    delayedQuery(inputValue, callback);
  };

  return isAsync ? (
    <AsyncSelect
      cacheOptions
      isClearable={isClearable}
      styles={{ ...colourStyles }}
      loadOptions={loadOptions}
      placeholder={placeholder}
      components={{ ValueContainer: custom }}
      defaultOptions={options}
      {...props}
    />
  ) : (
    <Select
      isClearable={props.isClearable}
      styles={{ ...colourStyles }}
      placeholder={props.placeholder}
      components={{ ValueContainer: custom }}
      {...props}
    />
  );
};

SelectComponent.propsTypes = {
  isClearable: bool,
};
SelectComponent.defaultProps = {
  isClearable: true,
};

export default SelectComponent;
