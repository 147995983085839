import DeleteIcon from "@material-ui/icons/Delete";
const index = ({
  value,
  type,
  disabled,
  onChange,
  width,
  label,
  isDeletable,
  onDelete,
  plus,
  minus,
  defaultValue,
}) => {
  return (
    <div className="input-container d-flex justify-content-between mt-3">
      <label className="m-0">{label}</label>
      <div className="d-flex">
       
        <button
          onClick={minus}
          type="button"
          disabled={defaultValue === 1 || value === 1}
          style={{
            backgroundColor: (defaultValue === 1 || value === 1) && "#ececec",
          }}
          className="counter-btn align-self-center"
        >
          -
        </button>
        <input
          style={{ width: width }}
          type="number"
          value={value ? value : defaultValue}
          min={1}
          onChange={onChange}
          disabled={disabled}
          className="input-counter-component"
        />
        <button
          onClick={plus}
          type="button"
          className="counter-btn align-self-center"
        >
          +
        </button>
        {isDeletable && (
          <span onClick={onDelete} className="mr-1 counter-delete-btn">
            <DeleteIcon style={{ color: "#ababab", marginLeft: 10 }} />
          </span>
        )}
      </div>
    </div>
  );
};

export default index;
