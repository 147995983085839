import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// styles
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./assets/styles/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";

// layout
import Layout from "./components/layout";

// views
import Index from "./views/Index";
import Login from "./views/login";
// import User from "./views/master-data/user";
// import Customer from "./views/master-data/customer";
// import Pegawai from "./views/master-data/employee";
// import Pabrik from "./views/master-data/pabrik";
// import Produk from "./views/master-data/produk";
// import AddProduct from "./views/master-data/produk/add";
// import AddSoquo from "./views/so-quo/add";
// import Pengaturan from "./views/pengaturan";
// import Transaksi from "./views/transaksi";
// import DetailTransaksi from "./views/transaksi/detail";
// import Kota from "./views/master-data/city";
// import Wilayah from "./views/master-data/region";
// import Area from "./views/master-data/area";
// import Owner from "./views/master-data/owner";
// import AddOrder from "./views/order/add";
// import ListOrder from "./views/order/list/";
// import DetailOrder from "./views/order/detail";
// import SOQUO from "./views/so-quo";
// import AuditTrail from "./views/audit-trail";
// import ReportTransaction from "./views/report/transaction";
// import ReportDaily from "./views/report/daily";
// import ManajemenFarmPS from "./views/pengaturan/farm-ps-management";
// import ReportProduct from "./views/report/product";
// import ReportCustomer from "./views/report/customer";
// import ReportSoquo from "./views/report/so-quo";

import PrivateRoute from "./components/commons/privateRoute";
import { getUser } from "./utils/user";
const AddOrder = lazy(() => import("./views/order/add"));
const AddProduct = lazy(() => import("./views/master-data/produk/add"));
const AddSoquo = lazy(() => import("./views/so-quo/add"));
const Area = lazy(() => import("./views/master-data/area"));
const AuditTrail = lazy(() => import("./views/audit-trail"));
const Customer = lazy(() => import("./views/master-data/customer"));
const DetailOrder = lazy(() => import("./views/order/detail"));
const DetailTransaksi = lazy(() => import("./views/transaksi/detail"));
const Kota = lazy(() => import("./views/master-data/city"));
const ListOrder = lazy(() => import("./views/order/list"));
const Notification = lazy(() => import("./views/notification"));
const ManajemenFarmPS = lazy(() =>
  import("./views/pengaturan/farm-ps-management")
);
const Owner = lazy(() => import("./views/master-data/owner"));
const Pabrik = lazy(() => import("./views/master-data/pabrik"));
const Pegawai = lazy(() => import("./views/master-data/employee"));
const Pengaturan = lazy(() => import("./views/pengaturan"));
const Produk = lazy(() => import("./views/master-data/produk"));
const ReportCustomer = lazy(() => import("./views/report/customer"));
const ReportDaily = lazy(() => import("./views/report/daily"));
const ReportProduct = lazy(() => import("./views/report/product"));
const ReportSoquo = lazy(() => import("./views/report/so-quo"));
const ReportTransaction = lazy(() => import("./views/report/transaction"));
const SOQUO = lazy(() => import("./views/so-quo"));
const Transaksi = lazy(() => import("./views/transaksi"));
const User = lazy(() => import("./views/master-data/user"));
const Wilayah = lazy(() => import("./views/master-data/region"));

const App = () => {
  const ContentRoute = () => {
    let role;
    if (getUser()) {
      role = getUser().role_code;
    }
    return (
      <Switch>
        <Layout>
          <Suspense fallback={<>Loading...</>}>
            <PrivateRoute
              path="/dashboard"
              roles={[
                "superadmin",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={Index}
            />
            <PrivateRoute
              path="/transaksi"
              roles={[
                "superadmin",
                "administrator",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={Transaksi}
            />
            <PrivateRoute
              path="/transaksi/:id"
              roles={[
                "superadmin",
                "administrator",
                "sales_admin",
                "sales_team",
                "ts",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={DetailTransaksi}
            />
            <PrivateRoute
              path="/so-quo"
              roles={[
                "superadmin",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={SOQUO}
            />
            <PrivateRoute
              path="/so-quo/add"
              roles={[
                "superadmin",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={AddSoquo}
            />
            <PrivateRoute
              path="/so-quo/detail/:id"
              roles={[
                "superadmin",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={AddSoquo}
            />
            <PrivateRoute
              path="/so-quo/edit/:id"
              roles={[
                "superadmin",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={AddSoquo}
            />
            <PrivateRoute
              path="/laporan-transaksi"
              roles={[
                "superadmin",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={ReportTransaction}
            />
            <PrivateRoute
              path="/laporan-harian"
              roles={[
                "superadmin",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={ReportDaily}
            />
            <PrivateRoute
              path="/laporan-produk"
              roles={[
                "superadmin",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
                "customer",
              ]}
              exact
              component={ReportProduct}
            />
            <PrivateRoute
              path="/laporan-customer"
              roles={[
                "superadmin",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={ReportCustomer}
            />
            <PrivateRoute
              path="/laporan-so-quo"
              roles={[
                "superadmin",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={ReportSoquo}
            />
            {/* ========= Super Admin ========= */}
            <PrivateRoute
              path="/area"
              roles={["superadmin"]}
              exact
              component={Area}
            />
            <PrivateRoute
              path="/wilayah"
              roles={["superadmin"]}
              exact
              component={Wilayah}
            />
            <PrivateRoute
              path="/kota"
              roles={["superadmin"]}
              exact
              component={Kota}
            />
            <PrivateRoute
              path="/customer"
              roles={["superadmin"]}
              exact
              component={Customer}
            />
            <PrivateRoute
              path="/owner"
              roles={["superadmin"]}
              exact
              component={Owner}
            />
            <PrivateRoute
              path="/pegawai"
              roles={["superadmin"]}
              exact
              component={Pegawai}
            />
            <PrivateRoute
              path="/user"
              roles={["superadmin"]}
              exact
              component={User}
            />
            <PrivateRoute
              path="/pabrik"
              roles={["superadmin"]}
              exact
              component={Pabrik}
            />
            <PrivateRoute
              path="/produk"
              roles={["superadmin", "customer"]}
              exact
              component={Produk}
            />
            <PrivateRoute
              path="/produk/add"
              roles={["superadmin"]}
              exact
              component={AddProduct}
            />
            <PrivateRoute
              path="/produk/detail/:id"
              roles={["superadmin", "customer"]}
              exact
              component={AddProduct}
            />
            <PrivateRoute
              path="/produk/edit/:id"
              roles={["superadmin"]}
              exact
              component={AddProduct}
            />
            <PrivateRoute
              path="/audit-trail"
              roles={["superadmin"]}
              exact
              component={AuditTrail}
            />
            {/* ============= Customer ============= */}
            <PrivateRoute
              path="/order/add"
              roles={["customer"]}
              exact
              component={AddOrder}
            />
            <PrivateRoute
              path="/order/edit/:id"
              roles={["customer", "sales_admin"]}
              exact
              component={AddOrder}
            />
            <PrivateRoute
              path="/order"
              roles={["customer"]}
              exact
              component={ListOrder}
            />
            <PrivateRoute
              path="/riwayat-order"
              roles={["customer"]}
              exact
              component={ListOrder}
            />
            <PrivateRoute
              path="/riwayat-order/detail/:id"
              roles={["customer"]}
              exact
              component={DetailOrder}
            />
            <PrivateRoute
              path="/manajemen-farm-ps"
              roles={["customer"]}
              exact
              component={ManajemenFarmPS}
            />
            <PrivateRoute
              path="/pengaturan"
              roles={[
                "superadmin",
                "customer",
                "administrator",
                "finance",
                "sales_admin",
                "sales_team",
                "technical_support",
                "gm",
                "avp",
                "rh",
              ]}
              exact
              component={Pengaturan}
            />
            <PrivateRoute
              path="/notification"
              roles={["sales_admin", "customer"]}
              exact
              component={Notification}
            />
            {/* <PrivateRoute roles={[]} exact component={() => <div></div>} /> */}
          </Suspense>
        </Layout>
      </Switch>
    );
  };
  return (
    <>
      <ToastContainer />
      <Switch>
        {/* <Route path="/" exact component={Login} /> */}
        <Route path="/login" exact component={Login} />
        <Route component={ContentRoute} />
      </Switch>
    </>
  );
};

export default App;
