import {
  GET_AREA_PENDING,
  GET_AREA_SUCCESS,
  GET_AREA_ERROR,
  POST_AREA_PENDING,
  POST_AREA_SUCCESS,
  POST_AREA_ERROR,
  PUT_AREA_PENDING,
  PUT_AREA_SUCCESS,
  PUT_AREA_ERROR,
  DELETE_AREA_PENDING,
  DELETE_AREA_SUCCESS,
  DELETE_AREA_ERROR,
  AREA_DATA,
  SET_AREA_MODAL,
} from "../../../actions/master-data/area";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const area = (state = initialState, action) => {
  switch (action.type) {
    case GET_AREA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_AREA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_AREA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_AREA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_AREA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_AREA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_AREA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_AREA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_AREA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_AREA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_AREA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_AREA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case AREA_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_AREA_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default area;
