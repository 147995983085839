import {
  GET_TRANSACTION_PENDING,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERROR,
  POST_TRANSACTION_PENDING,
  POST_TRANSACTION_SUCCESS,
  POST_TRANSACTION_ERROR,
  PUT_TRANSACTION_PENDING,
  PUT_TRANSACTION_SUCCESS,
  PUT_TRANSACTION_ERROR,
  DELETE_TRANSACTION_PENDING,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_ERROR,
  LEVEL_UP_STATUS_TRANSACTION_PENDING,
  LEVEL_UP_STATUS_TRANSACTION_SUCCESS,
  LEVEL_UP_STATUS_TRANSACTION_ERROR,
  EXPORT_TRANSACTION_PENDING,
  EXPORT_TRANSACTION_SUCCESS,
  EXPORT_TRANSACTION_ERROR,
  TRANSACTION_DATA,
  SET_TRANSACTION_MODAL,
} from "../../actions/transaction";

const initialState = {
  pending: false,
  error: null,
  data: null,
  exportData: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const transaction = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_TRANSACTION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_TRANSACTION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_TRANSACTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_TRANSACTION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_TRANSACTION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_TRANSACTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_TRANSACTION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_TRANSACTION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_TRANSACTION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case LEVEL_UP_STATUS_TRANSACTION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LEVEL_UP_STATUS_TRANSACTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LEVEL_UP_STATUS_TRANSACTION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case EXPORT_TRANSACTION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EXPORT_TRANSACTION_SUCCESS:
      return {
        ...state,
        pending: false,
        exportData: action.data,
      };
    case EXPORT_TRANSACTION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case TRANSACTION_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_TRANSACTION_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default transaction;
