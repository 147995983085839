import {
  SET_ORDER_MODAL,
  SET_ORDER_DATA,
  SET_ORDER_TYPE,
  SET_ORDER_DATA_FILTERED,
  SET_ORDER_DATA_SELECTED,
  SET_ORDER_PRODUCT_VALUE,
  SET_EDIT_ORDER_INITIAL_VALUE,
  SET_DETAIL_ORDER_DATA,
  SET_SELECTED_WAREHOUSE,
  SET_REORDER_INITIAL_VALUE,
  SET_DELIVERY_ORDER,
  SET_IS_FROM_ORDER,
} from "../../actions/order";

const initialState = {
  isOpenModal: false,
  modalType: "",
  packOptions: [
    { label: "LBG", value: "LBG" },
    { label: "BOX", value: "BOX" },
  ],
  orderData: [],
  orderType: "",
  selectedData: "",
  editOrderData: "",
  reorderData: "",
  detailOrderData: "",
  selectedWarehouse: null,
  deliveryOrder: [],
  isFromOrder: false,
};

const checkProductDuplicate = (initial, actionData) => {
  const filterByActionDataPack = initial.filter(
    (item) => item.pack === actionData.pack
  );
  const oldState = filterByActionDataPack.map((item) => item.product.value);
  let isAlreadyAdded;
  if (actionData.orderType !== "Farm") {
    isAlreadyAdded = oldState.includes(actionData.product.value);
  }
  if (actionData.orderType === "Farm") {
    isAlreadyAdded = initial.findIndex(
      (item) =>
        item.product.value === actionData.product.value &&
        item.warehouse.value === actionData.warehouse.value
    );
  }
  if (
    isAlreadyAdded === true ||
    (isAlreadyAdded !== -1 && isAlreadyAdded !== false)
  ) {
    const productIdx = initial.findIndex(
      (item) => item.product.value === actionData.product.value
    );
    const sum =
      parseInt(actionData.qty) +
      parseInt(
        initial[actionData.orderType === "Farm" ? isAlreadyAdded : productIdx]
          .qty
      );
    const updateDuplicateData = {
      ...initial[actionData.orderType === "Farm" ? isAlreadyAdded : productIdx],
      qty: sum.toString(),
    };
    initial[
      actionData.orderType === "Farm" ? isAlreadyAdded : productIdx
    ] = updateDuplicateData;
    return initial;
  }
  return [...initial, actionData];
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    case SET_ORDER_DATA:
      return {
        ...state,
        orderData:
          action.data === null
            ? []
            : state.orderData && state.orderData.length > 0
            ? action.data.length &&
              action.data[0]?.orderType &&
              action.data[0]?.orderType === "Farm"
              ? action.data
              : checkProductDuplicate(state.orderData, action.data)
            : [...state.orderData, action.data],
      };
    case SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.orderType,
      };
    case SET_ORDER_DATA_FILTERED:
      return {
        ...state,
        orderData: action.newData,
      };
    case SET_ORDER_DATA_SELECTED:
      return {
        ...state,
        selectedData: action.selectedData,
      };
    case SET_ORDER_PRODUCT_VALUE:
      return {
        ...state,
        orderData: action.value,
      };
    case SET_EDIT_ORDER_INITIAL_VALUE:
      return {
        ...state,
        editOrderData: action.value,
      };
    case SET_DETAIL_ORDER_DATA:
      return {
        ...state,
        detailOrderData: action.data,
      };
    case SET_SELECTED_WAREHOUSE:
      return {
        ...state,
        selectedWarehouse: action.data,
      };
    case SET_REORDER_INITIAL_VALUE:
      return {
        ...state,
        reorderData: action.value,
      };
    case SET_DELIVERY_ORDER:
      return {
        ...state,
        deliveryOrder: action.value,
      };
    case SET_IS_FROM_ORDER:
      return {
        ...state,
        isFromOrder: action.value,
      };
    default:
      return state;
  }
};

export default order;
