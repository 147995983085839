import {
  GET_WAREHOUSE_PENDING,
  GET_WAREHOUSE_SUCCESS,
  GET_WAREHOUSE_ERROR,
  POST_WAREHOUSE_PENDING,
  POST_WAREHOUSE_SUCCESS,
  POST_WAREHOUSE_ERROR,
  PUT_WAREHOUSE_PENDING,
  PUT_WAREHOUSE_SUCCESS,
  PUT_WAREHOUSE_ERROR,
  DELETE_WAREHOUSE_PENDING,
  DELETE_WAREHOUSE_SUCCESS,
  DELETE_WAREHOUSE_ERROR,
  WAREHOUSE_DATA,
  SET_WAREHOUSE_MODAL,
} from "../../../actions/customer/warehouse";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const warehouse = (state = initialState, action) => {
  switch (action.type) {
    case GET_WAREHOUSE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_WAREHOUSE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_WAREHOUSE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_WAREHOUSE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_WAREHOUSE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_WAREHOUSE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_WAREHOUSE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_WAREHOUSE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_WAREHOUSE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_WAREHOUSE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_WAREHOUSE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case WAREHOUSE_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_WAREHOUSE_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default warehouse;
