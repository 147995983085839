import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_PRODUCT_PENDING = "GET_PRODUCT_PENDING";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";
export const POST_PRODUCT_PENDING = "POST_PRODUCT_PENDING";
export const POST_PRODUCT_SUCCESS = "POST_PRODUCT_SUCCESS";
export const POST_PRODUCT_ERROR = "POST_PRODUCT_ERROR";
export const PUT_PRODUCT_PENDING = "PUT_PRODUCT_PENDING";
export const PUT_PRODUCT_SUCCESS = "PUT_PRODUCT_SUCCESS";
export const PUT_PRODUCT_ERROR = "PUT_PRODUCT_ERROR";
export const DELETE_PRODUCT_PENDING = "DELETE_PRODUCT_PENDING";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR";

export const PRODUCT_DATA = "PRODUCT_DATA";
export const SET_PRODUCT_MODAL = "SET_PRODUCT_MODAL";

// URL: URL_{URL}
const PRODUCT_URL = "/v1/product";
const DELETE_URL = `${PRODUCT_URL}?id=`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_PRODUCT_PENDING));
  API.get(PRODUCT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_PRODUCT_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_PRODUCT_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_PRODUCT_PENDING));
  API.put(PRODUCT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_PRODUCT_SUCCESS, res));
      // toastSuccess("Produk berhasil diubah");
      dispatch(setToastModal(true, true, "Produk berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_PRODUCT_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

//delete var name not allowed
const deletee = (id, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_PRODUCT_PENDING));
  API.delete(DELETE_URL + id)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_PRODUCT_SUCCESS, res));
      // toastSuccess("Produk berhasil dihapus");
      dispatch(setToastModal(true, true, "Produk berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_PRODUCT_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_PRODUCT_PENDING));
  API.post(PRODUCT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_PRODUCT_SUCCESS, res));
      // toastSuccess("Produk berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Produk berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_PRODUCT_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Product = { get, put, deletee, post };
export default Product;

export const setProductData = (data) => (dispatch) =>
  dispatch({ type: PRODUCT_DATA, data });

export const setProductModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_PRODUCT_MODAL, { modalType: modalType, isOpen: isOpen })
  );
