import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { ToastSuccess, ToastError } from "../../../../components/commons/toast";
import getToken from "../../../../utils/token";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_FACTORY_PENDING = "GET_FACTORY_PENDING";
export const GET_FACTORY_SUCCESS = "GET_FACTORY_SUCCESS";
export const GET_FACTORY_ERROR = "GET_FACTORY_ERROR";
export const POST_FACTORY_PENDING = "POST_FACTORY_PENDING";
export const POST_FACTORY_SUCCESS = "POST_FACTORY_SUCCESS";
export const POST_FACTORY_ERROR = "POST_FACTORY_ERROR";
export const PUT_FACTORY_PENDING = "PUT_FACTORY_PENDING";
export const PUT_FACTORY_SUCCESS = "PUT_FACTORY_SUCCESS";
export const PUT_FACTORY_ERROR = "PUT_FACTORY_ERROR";
export const DELETE_FACTORY_PENDING = "DELETE_FACTORY_PENDING";
export const DELETE_FACTORY_SUCCESS = "DELETE_FACTORY_SUCCESS";
export const DELETE_FACTORY_ERROR = "DELETE_FACTORY_ERROR";

export const FACTORY_DATA = "FACTORY_DATA";
export const SET_FACTORY_MODAL = "SET_FACTORY_MODAL";

// URL: URL_{URL}
const FACTORY_URL = "/v1/factory";
const DELETE_URL = `${FACTORY_URL}?id=`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_FACTORY_PENDING));
  API.get(FACTORY_URL, {
    params: param,
    headers: getToken()?.headers,
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_FACTORY_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_FACTORY_ERROR));
      // ToastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_FACTORY_PENDING));
  API.post(FACTORY_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_FACTORY_SUCCESS, res));
      // ToastSuccess("Pabrik berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Pabrik berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_FACTORY_ERROR));
      dispatch(setToastModal(true, false, err?.response?.data?.message));
      // ToastError(err?.response?.data?.message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_FACTORY_PENDING));
  API.put(FACTORY_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_FACTORY_SUCCESS, res));
      // ToastSuccess("Pabrik berhasil diubah");
      dispatch(setToastModal(true, true, "Pabrik berhasil diubah"));

      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_FACTORY_ERROR));
      dispatch(setToastModal(true, false, err?.response?.data?.message));
      // ToastError(err?.response?.data?.message);
    });
};

//delete var name not allowed
const deletee = (id, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_FACTORY_PENDING));
  API.delete(DELETE_URL + id)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_FACTORY_SUCCESS, res));
      // ToastSuccess("Pabrik berhasil dihapus");
      dispatch(setToastModal(true, true, "Pabrik berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_FACTORY_ERROR));
      // ToastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Factory = { post, get, put, deletee };
export default Factory;

export const setFactoryData = (data) => (dispatch) =>
  dispatch({ type: FACTORY_DATA, data });

export const setFactoryModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_FACTORY_MODAL, { modalType: modalType, isOpen: isOpen })
  );
