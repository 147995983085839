import API from "../API";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
// import { toastSuccess, toastError } from "../../../components/commons/toast";
import getToken from "../../../utils/token";
import { setToastModal } from "../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_SOQUO_PENDING = "GET_SOQUO_PENDING";
export const GET_SOQUO_SUCCESS = "GET_SOQUO_SUCCESS";
export const GET_SOQUO_ERROR = "GET_SOQUO_ERROR";
export const POST_SOQUO_PENDING = "POST_SOQUO_PENDING";
export const POST_SOQUO_SUCCESS = "POST_SOQUO_SUCCESS";
export const POST_SOQUO_ERROR = "POST_SOQUO_ERROR";
export const PUT_SOQUO_PENDING = "PUT_SOQUO_PENDING";
export const PUT_SOQUO_SUCCESS = "PUT_SOQUO_SUCCESS";
export const PUT_SOQUO_ERROR = "PUT_SOQUO_ERROR";
export const DELETE_SOQUO_PENDING = "DELETE_SOQUO_PENDING";
export const DELETE_SOQUO_SUCCESS = "DELETE_SOQUO_SUCCESS";
export const DELETE_SOQUO_ERROR = "DELETE_SOQUO_ERROR";
export const APPROVAL_SOQUO_PENDING = "APPROVAL_SOQUO_PENDING";
export const APPROVAL_SOQUO_SUCCESS = "APPROVAL_SOQUO_SUCCESS";
export const APPROVAL_SOQUO_ERROR = "APPROVAL_SOQUO_ERROR";

export const SOQUO_DATA = "SOQUO_DATA";
export const SET_SOQUO_MODAL = "SET_SOQUO_MODAL";
export const SET_ADD_SOQUO_STEP = "SET_ADD_SOQUO_STEP";
export const SET_ADD_SOQUO_DATA = "SET_ADD_SOQUO_DATA";

// URL: URL_{URL}
const SOQUO_URL = "/v1/so_quo";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_SOQUO_PENDING));
  API.get(SOQUO_URL, { params: param, headers: getToken()?.headers })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_SOQUO_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_SOQUO_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_SOQUO_PENDING));
  API.post(SOQUO_URL, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_SOQUO_SUCCESS, res));
      // toastSuccess("Permohonan SO/QUO berhasil ditambahkan");
      dispatch(
        setToastModal(true, true, "Permohonan SO/QUO berhasil ditambahkan")
      );
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_SOQUO_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_SOQUO_PENDING));
  API.put(SOQUO_URL, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_SOQUO_SUCCESS, res));
      // toastSuccess("Permohonan SO/QUO berhasil diubah");
      dispatch(setToastModal(true, true, "Permohonan SO/QUO berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_SOQUO_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_SOQUO_PENDING));
  API.delete(SOQUO_URL, { params: param }, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_SOQUO_SUCCESS, res));
      // toastSuccess("Permohonan SO/QUO berhasil dihapus");
      dispatch(setToastModal(true, true, "Permohonan SO/QUO berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_SOQUO_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const approval = (param, callback) => (dispatch) => {
  dispatch(actionPending(APPROVAL_SOQUO_PENDING));
  API.post(`${SOQUO_URL}/forward_approve`, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(APPROVAL_SOQUO_SUCCESS, res));
      // toastSuccess("Permohonan SO/QUO berhasil diteruskan");
      dispatch(
        setToastModal(true, true, "Permohonan SO/QUO berhasil diteruskan")
      );
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(APPROVAL_SOQUO_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Soquo = { get, post, put, deleted, approval };
export default Soquo;

export const setSoquoData = (data) => (dispatch) =>
  dispatch({ type: SOQUO_DATA, data });

export const setSoquoModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_SOQUO_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );

export const setAddSoquoStep = (step) => (dispatch) => {
  dispatch({ type: SET_ADD_SOQUO_STEP, step });
};

export const setAddSoquoData = (data) => (dispatch) => {
  dispatch({ type: SET_ADD_SOQUO_DATA, data });
};
