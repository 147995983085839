import {
  GET_PRODUCT_TYPE_PENDING,
  GET_PRODUCT_TYPE_SUCCESS,
  GET_PRODUCT_TYPE_ERROR,
} from "../../../actions/master-data/product-type";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const productType = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_TYPE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_PRODUCT_TYPE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default productType;
