import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import { Container, Row, Col } from "reactstrap";
import moment from "moment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import TodayIcon from "@material-ui/icons/Today";
import DescriptionIcon from "@material-ui/icons/Description";
import Counter from "../components/dashboard/counter";
import { setActiveMenu } from "../store/actions";
import Dashboard from "../store/actions/dashboard";
import { history } from "../utils";

const Index = ({ onSetActiveMenu, dashboard }) => {
  const dispatch = useDispatch();
  const moveToTransaction = () => {
    onSetActiveMenu("Transaksi");
    history.push("/transaksi");
  };

  const moveToSoquo = () => {
    onSetActiveMenu("SO/QUO");
    history.push("/so-quo");
  };

  useEffect(() => {
    dispatch(Dashboard.get());
  }, []);

  let data = {};

  if (dashboard?.data?.data?.data) {
    const dashboardData = dashboard.data.data.data;
    data.month_total_order = dashboardData.month_total_order;
    data.day_total_order = dashboardData.day_total_order;
    data.month_total_soquo = dashboardData.month_total_soquo;
    data.latest_order = dashboardData.latest_order.data.slice(0, 5);
    data.latest_soquo = dashboardData.latest_soquo.data.slice(0, 5);
  }
  return (
    <Container className="p-0">
      <label className="text-title content-title">Dashboard</label>
      <Row className="m-0 d-flex justify-content-between dashboard-content">
        <Col className="pl-0" lg={4}>
          <Counter
            label="Order Bulan Ini"
            icon={DateRangeIcon}
            value={data?.month_total_order}
          />
        </Col>
        <Col className="pl-0" lg={4}>
          <Counter
            label="Order Hari Ini"
            icon={TodayIcon}
            value={data?.day_total_order}
          />
        </Col>
        <Col className="pl-0" lg={4}>
          <Counter
            label="Pengajuan SO/QUO"
            icon={DescriptionIcon}
            value={data?.month_total_soquo}
          />
        </Col>
      </Row>
      <Row className="m-0 d-flex justify-content-between">
        <Col lg={8} className="pl-0 pb-3">
          <Row className="m-0 justify-content-between">
            <label className="font-weight-bold mb-3">
              Daftar Pesanan Terakhir
            </label>
            <label
              className="dashboard-link mb-3 align-self-center"
              onClick={moveToTransaction}
            >
              Lihat Semua Order
            </label>
          </Row>
          <MaterialTable
            columns={[
              {
                title: "No",
                field: "no",
                width: 40,
              },
              {
                title: "Tanggal Transaksi",
                render: ({ transaction_date }) => {
                  return transaction_date
                    ? moment(transaction_date).format("DD MMM YYYY")
                    : "-";
                },
              },
              {
                title: "Nama Customer",
                field: "customer_name",
              },
              {
                title: "Jumlah Order",
                render: ({ order_qty }) => {
                  order_qty = order_qty
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                  return `${order_qty} Kg`;
                },
              },
              {
                title: "Status",
                render: ({ status }) => {
                  return status === 0
                    ? "Draft"
                    : status === 1
                    ? "BARU"
                    : status === 2
                    ? "DIPROSES"
                    : status === 3
                    ? "DIKIRIM"
                    : "SELESAI";
                },
              },
            ]}
            data={
              data &&
              data.latest_order &&
              data.latest_order.map((item, idx) => ({
                no: idx + 1,
                transaction_date: item.created_at,
                customer_name: item.customer_name,
                order_qty: item.total_qty_kg,
                status: item.status,
              }))
            }
            options={{
              paging: false,
              showTitle: false,
              search: false,
              sorting: false,
              headerStyle: {
                backgroundColor: "#fff",
                fontWeight: "bold",
              },
              cellStyle: {
                height: 50,
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Tidak ada data",
              },
            }}
            components={{
              Toolbar: () => <div />,
            }}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <Row className="m-0 justify-content-between">
            <label className="font-weight-bold mb-3">
              Daftar Pengajuan SO/QUO
            </label>
            <label
              className="dashboard-link mb-3 align-self-center"
              onClick={moveToSoquo}
            >
              Selengkapnya
            </label>
          </Row>
          <MaterialTable
            columns={[
              {
                title: "No",
                field: "no",
                width: 40,
              },
              {
                title: "Nama Customer",
                field: "customer_name",
              },
            ]}
            data={
              data &&
              data.latest_soquo &&
              data.latest_soquo.map((item, idx) => ({
                no: idx + 1,
                customer_name: item.customer_name,
              }))
            }
            options={{
              paging: false,
              showTitle: false,
              search: false,
              sorting: false,
              headerStyle: {
                backgroundColor: "#fff",
                fontWeight: "bold",
              },
              cellStyle: {
                height: 50,
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Tidak ada data",
              },
            }}
            components={{
              Toolbar: () => <div />,
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ global, dashboard }) => {
  return { global, dashboard };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetActiveMenu: (menu) => dispatch(setActiveMenu(menu)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
