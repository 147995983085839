import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  setOrderType,
  setOrderData,
  setSelectedWarehouse,
} from "../../../store/actions/order";
import MenuItem from "./MenuItem";
import DashboardIcon from "../../../assets/icon/dashboard-icon";
import ShopingCartIcon from "../../../assets/icon/shopping-cart-icon";
import DescriptionIcon from "../../../assets/icon/description-icon";
import LibraryBooksIcon from "../../../assets/icon/library-books-icon";
import BookIcon from "../../../assets/icon/book-icon";
import VerifiedUserIcon from "../../../assets/icon/verified-user-icon";
import SettingsIcon from "../../../assets/icon/settings-icon";
import ArchieveIcon from "../../../assets/icon/archieve-icon";
import ReceiptIcon from "../../../assets/icon/receipt-icon";
import { getUser } from "../../../utils/user";
import { history } from "../../../utils";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import "./sidebar.scss";

const Index = ({
  onChange,
  selectedItem,
  onSetOrderType,
  onSetOrderData,
  onSetSelectedWarehouse,
  setIsActive,
  isActive
}) => {
  let user = getUser();
  const [isAdmin, setIsAdmin] = useState(user?.role_code);
  const [btnDropdown, setBtnDropdown] = useState(false);
  const toggle = () => setBtnDropdown(!btnDropdown);
  const onItemClicked = (item) => {
    onChange(item);
    setIsActive(false)
  } 
  const boxRef = useRef(null);
  // boxOutsideClick will be true on outside click
 
  useEffect(() => {
    if (history?.location?.pathname === "/dashboard") onChange("Dashboard");
    if (history?.location?.pathname === "/order") onChange("Order");
    if (history?.location?.pathname === "/riwayat-order") onChange("Riwayat Order");
    if (history?.location?.pathname === "/manajemen-farm-ps")
      onChange("Pengaturan");
  }, []);

  const addOrder = (orderType) => {
    onSetOrderType(orderType);
    onSetOrderData(null);
    onSetSelectedWarehouse(null);
    history.push("/order/add", { from: "Add Order" });
  };
 useEffect(()=>{
  function handleClickOutside(event) {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
 },[boxRef])
  return (
    <div className="sidebar" onClickOutside={()=>{setIsActive(false)}} ref={boxRef}>
      {isAdmin === "superadmin" ? (
        <>
          <NavLink to="/dashboard">
            <MenuItem
              title="Dashboard"
              onClick={() => onItemClicked("Dashboard")}
              active={selectedItem === "Dashboard"}
              icon={DashboardIcon}
            />
          </NavLink>
          <NavLink to="/transaksi">
            <MenuItem
              title="Transaksi"
              onClick={() => onItemClicked("Transaksi")}
              active={selectedItem === "Transaksi"}
              icon={ShopingCartIcon}
            />
          </NavLink>
          <NavLink to="/so-quo">
            <MenuItem
              title="SO/QUO"
              onClick={() => onItemClicked("SO/QUO")}
              active={selectedItem === "SO/QUO"}
              icon={DescriptionIcon}
            />
          </NavLink>
          <MenuItem
            title="Master Data"
            onClick={() => onItemClicked("Master Data")}
            active={selectedItem === "Master Data"}
            icon={LibraryBooksIcon}
            child={[
              {
                title: "Produk",
                link: "/produk",
              },
              {
                title: "Pabrik",
                link: "/pabrik",
              },
              {
                title: "Kota",
                link: "/kota",
              },
              {
                title: "Wilayah",
                link: "/wilayah",
              },
              {
                title: "Area",
                link: "/area",
              },
              {
                title: "Pegawai",
                link: "/pegawai",
              },
              {
                title: "Owner",
                link: "/owner",
              },
              {
                title: "Customer",
                link: "/customer",
              },
              {
                title: "Pengguna",
                link: "/user",
              },
            ]}
          />
          <MenuItem
            title="Laporan"
            onClick={() => onItemClicked("Laporan")}
            active={selectedItem === "Laporan"}
            icon={BookIcon}
            child={[
              {
                title: "Transaksi",
                link: "/laporan-transaksi",
              },
              {
                title: "Harian",
                link: "/laporan-harian",
              },
              {
                title: "Produk",
                link: "/laporan-produk",
              },
              {
                title: "Customer",
                link: "/laporan-customer",
              },
              {
                title: "SO/QUO",
                link: "/laporan-so-quo",
              },
            ]}
          />
          <NavLink to="/audit-trail">
            <MenuItem
              title="Audit Trail"
              onClick={() => onItemClicked("Audit Trail")}
              active={selectedItem === "Audit Trail"}
              icon={VerifiedUserIcon}
            />
          </NavLink>
          <NavLink to="/pengaturan">
            <MenuItem
              title="Pengaturan"
              onClick={() => onItemClicked("Pengaturan")}
              active={selectedItem === "Pengaturan"}
              icon={SettingsIcon}
            />
          </NavLink>
        </>
      ) : isAdmin === "administrator" ? (
        <>
          <NavLink to="/dashboard">
            <MenuItem
              title="Dashboard"
              onClick={() => onItemClicked("Dashboard")}
              active={selectedItem === "Dashboard"}
              icon={DashboardIcon}
            />
          </NavLink>
          <NavLink to="/transaksi">
            <MenuItem
              title="Transaksi"
              onClick={() => onItemClicked("Transaksi")}
              active={selectedItem === "Transaksi"}
              icon={ShopingCartIcon}
            />
          </NavLink>
          <NavLink to="/so-quo">
            <MenuItem
              title="SO/QUO"
              onClick={() => onItemClicked("SO/QUO")}
              active={selectedItem === "SO/QUO"}
              icon={DescriptionIcon}
            />
          </NavLink>

          <MenuItem
            title="Laporan"
            onClick={() => onItemClicked("Laporan")}
            active={selectedItem === "Laporan"}
            icon={BookIcon}
            child={[
              {
                title: "Transaksi",
                link: "/laporan-transaksi",
              },
              {
                title: "Harian",
                link: "/laporan-harian",
              },
              {
                title: "Produk",
                link: "/laporan-produk",
              },
              {
                title: "Customer",
                link: "/laporan-customer",
              },
              {
                title: "SO/QUO",
                link: "/laporan-so-quo",
              },
            ]}
          />
          <NavLink to="/">
            <MenuItem
              title="Audit Trail"
              onClick={() => onItemClicked("Audit Trail")}
              active={selectedItem === "Audit Trail"}
              icon={VerifiedUserIcon}
            />
          </NavLink>
          <NavLink to="/pengaturan">
            <MenuItem
              title="Pengaturan"
              onClick={() => onItemClicked("Pengaturan")}
              active={selectedItem === "Pengaturan"}
              icon={SettingsIcon}
            />
          </NavLink>
        </>
      ) : isAdmin === "sales_admin" ||
        isAdmin === "sales_team" ||
        isAdmin === "technical_support" ||
        isAdmin === "gm" ||
        isAdmin === "avp" ||
        isAdmin === "rh" ? (
        <>
          <NavLink to="/dashboard">
            <MenuItem
              title="Dashboard"
              onClick={() => onItemClicked("Dashboard")}
              active={selectedItem === "Dashboard"}
              icon={DashboardIcon}
            />
          </NavLink>
          <NavLink to="/transaksi">
            <MenuItem
              title="Transaksi"
              onClick={() => onItemClicked("Transaksi")}
              active={selectedItem === "Transaksi"}
              icon={ShopingCartIcon}
            />
          </NavLink>
          <NavLink to="/so-quo">
            <MenuItem
              title="SO/QUO"
              onClick={() => onItemClicked("SO/QUO")}
              active={selectedItem === "SO/QUO"}
              icon={DescriptionIcon}
            />
          </NavLink>
          <MenuItem
            title="Laporan"
            onClick={() => onItemClicked("Laporan")}
            active={selectedItem === "Laporan"}
            icon={BookIcon}
            child={[
              {
                title: "Transaksi",
                link: "/laporan-transaksi",
              },
              {
                title: "Harian",
                link: "/laporan-harian",
              },
              {
                title: "Produk",
                link: "/laporan-produk",
              },
              {
                title: "Customer",
                link: "/laporan-customer",
              },
              {
                title: "SO/QUO",
                link: "/laporan-so-quo",
              },
            ]}
          />
          <NavLink to="/pengaturan">
            <MenuItem
              title="Pengaturan"
              onClick={() => onItemClicked("Pengaturan")}
              active={selectedItem === "Pengaturan"}
              icon={SettingsIcon}
            />
          </NavLink>
        </>
      ) : isAdmin === "finance" ? (
        <>
          <NavLink to="/dashboard">
            <MenuItem
              title="Dashboard"
              onClick={() => onItemClicked("Dashboard")}
              active={selectedItem === "Dashboard"}
              icon={DashboardIcon}
            />
          </NavLink>
          <NavLink to="/so-quo">
            <MenuItem
              title="SO/QUO"
              onClick={() => onItemClicked("SO/QUO")}
              active={selectedItem === "SO/QUO"}
              icon={DescriptionIcon}
            />
          </NavLink>
          <MenuItem
            title="Laporan"
            onClick={() => onItemClicked("Laporan")}
            active={selectedItem === "Laporan"}
            icon={BookIcon}
            child={[
              {
                title: "Transaksi",
                link: "/laporan-transaksi",
              },
              {
                title: "Harian",
                link: "/laporan-harian",
              },
              {
                title: "Produk",
                link: "/laporan-produk",
              },
              {
                title: "Customer",
                link: "/laporan-customer",
              },
              {
                title: "SO/QUO",
                link: "/laporan-so-quo",
              },
            ]}
          />
          <NavLink to="/pengaturan">
            <MenuItem
              title="Pengaturan"
              onClick={() => onItemClicked("Pengaturan")}
              active={selectedItem === "Pengaturan"}
              icon={SettingsIcon}
            />
          </NavLink>
        </>
      ) : isAdmin === "customer" ? (
        <>
          <ButtonDropdown isOpen={btnDropdown} toggle={toggle}>
            <DropdownToggle
              color="primary"
              siee="sm"
              className="button-add-new-order"
            >
              + Buat Order Baru
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => addOrder("Poultry Shop")}>
                Poultry Shop
              </DropdownItem>
              <DropdownItem divider style={{ margin: "0.2rem 0" }} />
              <DropdownItem onClick={() => addOrder("Farm")}>Farm</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <NavLink to="/order">
            <MenuItem
              title="Order"
              onClick={() => onItemClicked("Order")}
              active={selectedItem === "Order"}
              icon={ShopingCartIcon}
            />
          </NavLink>
          <NavLink to="/produk">
            <MenuItem
              title="Produk"
              onClick={() => onItemClicked("Produk")}
              active={selectedItem === "Produk"}
              icon={ArchieveIcon}
            />
          </NavLink>
          <NavLink to="/riwayat-order">
            <MenuItem
              title="Riwayat Order"
              onClick={() => onItemClicked("Riwayat Order")}
              active={selectedItem === "Riwayat Order"}
              icon={ReceiptIcon}
            />
          </NavLink>
          <NavLink to="/laporan-produk">
            <MenuItem
              title="Laporan Order"
              onClick={() => onItemClicked("Laporan Order")}
              active={selectedItem === "Laporan Order"}
              icon={DescriptionIcon}
            />
          </NavLink>
          <NavLink to="/pengaturan">
            <MenuItem
              title="Pengaturan"
              onClick={() => onItemClicked("Pengaturan")}
              active={selectedItem === "Pengaturan"}
              icon={SettingsIcon}
            />
          </NavLink>
        </>
      ) : (
        <span>blank</span>
      )}
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetOrderType: (orderType) => dispatch(setOrderType(orderType)),
    onSetOrderData: (data) => dispatch(setOrderData(data)),
    onSetSelectedWarehouse: (data) => dispatch(setSelectedWarehouse(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
