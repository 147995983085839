import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_EMPLOYEE_PENDING = "GET_EMPLOYEE_PENDING";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_ERROR = "GET_EMPLOYEE_ERROR";
export const POST_EMPLOYEE_PENDING = "POST_EMPLOYEE_PENDING";
export const POST_EMPLOYEE_SUCCESS = "POST_EMPLOYEE_SUCCESS";
export const POST_EMPLOYEE_ERROR = "POST_EMPLOYEE_ERROR";
export const PUT_EMPLOYEE_PENDING = "PUT_EMPLOYEE_PENDING";
export const PUT_EMPLOYEE_SUCCESS = "PUT_EMPLOYEE_SUCCESS";
export const PUT_EMPLOYEE_ERROR = "PUT_EMPLOYEE_ERROR";
export const DELETE_EMPLOYEE_PENDING = "DELETE_EMPLOYEE_PENDING";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_ERROR = "DELETE_EMPLOYEE_ERROR";
export const RESET_EMPLOYEE_PASSWORD_PENDING =
  "RESET_EMPLOYEE_PASSWORD_PENDING";
export const RESET_EMPLOYEE_PASSWORD_SUCCESS =
  "RESET_EMPLOYEE_PASSWORD_SUCCESS";
export const RESET_EMPLOYEE_PASSWORD_ERROR = "RESET_EMPLOYEE_PASSWORD_ERROR";
export const CHANGE_PASSWORD_EMPLOYEE_PENDING =
  "CHANGE_PASSWORD_EMPLOYEE_PENDING";
export const CHANGE_PASSWORD_EMPLOYEE_SUCCESS =
  "CHANGE_PASSWORD_EMPLOYEE_SUCCESS";
export const CHANGE_PASSWORD_EMPLOYEE_ERROR = "CHANGE_PASSWORD_EMPLOYEE_ERROR";

export const EMPLOYEE_DATA = "EMPLOYEE_DATA";
export const SET_EMPLOYEE_MODAL = "SET_EMPLOYEE_MODAL";

// URL: URL_{URL}
const EMPLOYEE_URL = "/v1/user_employee";
const EMPLOYEE_RESET_PASSWORD_URL = "/v1/user_employee/reset_password";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_EMPLOYEE_PENDING));
  API.get(EMPLOYEE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_EMPLOYEE_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_EMPLOYEE_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_EMPLOYEE_PENDING));
  API.post(EMPLOYEE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_EMPLOYEE_SUCCESS, res));
      // toastSuccess("Pegawai berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Pegawai berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_EMPLOYEE_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_EMPLOYEE_PENDING));
  API.put(EMPLOYEE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_EMPLOYEE_SUCCESS, res));
      // toastSuccess("Pegawai berhasil diubah");
      dispatch(setToastModal(true, true, "Pegawai berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_EMPLOYEE_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_EMPLOYEE_PENDING));
  API.delete(EMPLOYEE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_EMPLOYEE_SUCCESS, res));
      // toastSuccess("Pegawai berhasil dihapus");
      dispatch(setToastModal(true, true, "Pegawai berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_EMPLOYEE_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const resetPassword = (id, callback) => (dispatch) => {
  dispatch(actionPending(RESET_EMPLOYEE_PASSWORD_PENDING));
  const url = `${EMPLOYEE_RESET_PASSWORD_URL}?id=${id}`;
  API.put(url)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(RESET_EMPLOYEE_PASSWORD_SUCCESS, res));
      // toastSuccess("Berhasil mereset password pegawai");
      dispatch(setToastModal(true, true, "Berhasil mereset password pegawai"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(RESET_EMPLOYEE_PASSWORD_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const changePassword = (param, callback) => (dispatch) => {
  dispatch(actionPending(CHANGE_PASSWORD_EMPLOYEE_PENDING));
  API.post(`${EMPLOYEE_URL}/change_password`, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(CHANGE_PASSWORD_EMPLOYEE_SUCCESS, res));
      // toastSuccess("Password Pegawai berhasil diubah");
      dispatch(setToastModal(true, true, "Password Pegawai berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(CHANGE_PASSWORD_EMPLOYEE_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Employee = { get, post, put, deleted, resetPassword, changePassword };
export default Employee;

export const setEmployeeData = (data) => (dispatch) =>
  dispatch({ type: EMPLOYEE_DATA, data });

export const setEmployeeModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_EMPLOYEE_MODAL, { modalType: modalType, isOpen: isOpen })
  );
