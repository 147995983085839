import {
  GET_REPORT_SOQUO_PENDING,
  GET_REPORT_SOQUO_SUCCESS,
  GET_REPORT_SOQUO_ERROR,
  EXPORT_REPORT_SOQUO_PENDING,
  EXPORT_REPORT_SOQUO_SUCCESS,
  EXPORT_REPORT_SOQUO_ERROR,
  EXPORT_PDF_REPORT_SOQUO_PENDING,
  EXPORT_PDF_REPORT_SOQUO_SUCCESS,
  EXPORT_PDF_REPORT_SOQUO_ERROR,
  REPORT_SOQUO_DATA,
  SET_REPORT_SOQUO_MODAL,
} from "../../../actions/report/so-quo";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const soquo = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_SOQUO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_REPORT_SOQUO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_REPORT_SOQUO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case EXPORT_REPORT_SOQUO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EXPORT_REPORT_SOQUO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case EXPORT_REPORT_SOQUO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case EXPORT_PDF_REPORT_SOQUO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EXPORT_PDF_REPORT_SOQUO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case EXPORT_PDF_REPORT_SOQUO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case REPORT_SOQUO_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_REPORT_SOQUO_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default soquo;
