import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_PRODUCT_TYPE_PENDING = "GET_PRODUCT_TYPE_PENDING";
export const GET_PRODUCT_TYPE_SUCCESS = "GET_PRODUCT_TYPE_SUCCESS";
export const GET_PRODUCT_TYPE_ERROR = "GET_PRODUCT_TYPE_ERROR";

export const PRODUCT_TYPE_DATA = "PRODUCT_TYPE_DATA";
export const SET_PRODUCT_TYPE_MODAL = "SET_PRODUCT_TYPE_MODAL";

// URL: URL_{URL}
const PRODUCT_TYPE_URL = "/v1/product_type";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_PRODUCT_TYPE_PENDING));
  API.get(PRODUCT_TYPE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_PRODUCT_TYPE_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_PRODUCT_TYPE_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const ProductType = { get };
export default ProductType;
