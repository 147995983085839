export const SET_ADD_PRODUCT_STEP = "SET_ADD_PRODUCT_STEP";
export const SET_ADD_PRODUCT_DATA = "SET_ADD_PRODUCT_DATA";

export const setAddProductStep = (step) => (dispatch) => {
  dispatch({ type: SET_ADD_PRODUCT_STEP, step });
};

export const setAddProductData = (data) => (dispatch) => {
  dispatch({ type: SET_ADD_PRODUCT_DATA, data });
};
