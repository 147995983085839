import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_AREA_PENDING = "GET_AREA_PENDING";
export const GET_AREA_SUCCESS = "GET_AREA_SUCCESS";
export const GET_AREA_ERROR = "GET_AREA_ERROR";
export const POST_AREA_PENDING = "POST_AREA_PENDING";
export const POST_AREA_SUCCESS = "POST_AREA_SUCCESS";
export const POST_AREA_ERROR = "POST_AREA_ERROR";
export const PUT_AREA_PENDING = "PUT_AREA_PENDING";
export const PUT_AREA_SUCCESS = "PUT_AREA_SUCCESS";
export const PUT_AREA_ERROR = "PUT_AREA_ERROR";
export const DELETE_AREA_PENDING = "DELETE_AREA_PENDING";
export const DELETE_AREA_SUCCESS = "DELETE_AREA_SUCCESS";
export const DELETE_AREA_ERROR = "DELETE_AREA_ERROR";

export const AREA_DATA = "AREA_DATA";
export const SET_AREA_MODAL = "SET_AREA_MODAL";

// URL: URL_{URL}
const AREA_URL = "/v1/area";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_AREA_PENDING));
  API.get(AREA_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_AREA_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_AREA_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_AREA_PENDING));
  API.post(AREA_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_AREA_SUCCESS, res));
      // toastSuccess("Area berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Area berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_AREA_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_AREA_PENDING));
  API.put(AREA_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_AREA_SUCCESS, res));
      // toastSuccess("Area berhasil diubah");
      dispatch(setToastModal(true, true, "Area berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_AREA_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_AREA_PENDING));
  API.delete(AREA_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_AREA_SUCCESS, res));
      // toastSuccess("Area berhasil dihapus");
      dispatch(setToastModal(true, true, "Area berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_AREA_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Area = { get, post, put, deleted };
export default Area;

export const setAreaData = (data) => (dispatch) =>
  dispatch({ type: AREA_DATA, data });

export const setAreaModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_AREA_MODAL, { modalType: modalType, isOpen: isOpen })
  );
