import {
  GET_REPORT_DAILY_PENDING,
  GET_REPORT_DAILY_SUCCESS,
  GET_REPORT_DAILY_ERROR,
  EXPORT_REPORT_DAILY_PENDING,
  EXPORT_REPORT_DAILY_SUCCESS,
  EXPORT_REPORT_DAILY_ERROR,
  REPORT_DAILY_DATA,
  SET_REPORT_DAILY_MODAL,
} from "../../../actions/report/daily";

const initialState = {
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const daily = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_DAILY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_REPORT_DAILY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_REPORT_DAILY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case EXPORT_REPORT_DAILY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EXPORT_REPORT_DAILY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case EXPORT_REPORT_DAILY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case REPORT_DAILY_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_REPORT_DAILY_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default daily;
