import axios from "axios";

let user = JSON.parse(localStorage.getItem("user_soquo"));

const instance = axios.create({
  baseURL: process.env.REACT_APP_SOQUO_FEED_ORDER_URL,
  headers: { "x-auth-token": user !== null ? user.token : "" },
});

// instance.defaults.headers.common["x-auth-token"] = user?.token;

export default instance;
