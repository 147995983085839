import {
  GET_CUSTOMER_PENDING,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_ERROR,
  POST_CUSTOMER_PENDING,
  POST_CUSTOMER_SUCCESS,
  POST_CUSTOMER_ERROR,
  PUT_CUSTOMER_PENDING,
  PUT_CUSTOMER_SUCCESS,
  PUT_CUSTOMER_ERROR,
  DELETE_CUSTOMER_PENDING,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_ERROR,
  RESET_CUSTOMER_PASSWORD_PENDING,
  RESET_CUSTOMER_PASSWORD_SUCCESS,
  RESET_CUSTOMER_PASSWORD_ERROR,
  CHANGE_PASSWORD_CUSTOMER_PENDING,
  CHANGE_PASSWORD_CUSTOMER_SUCCESS,
  CHANGE_PASSWORD_CUSTOMER_ERROR,
  CUSTOMER_DATA,
  SET_CUSTOMER_MODAL,
} from "../../../actions/master-data/customer";

const initialState = {
  newPass: null,
  pending: false,
  error: null,
  data: null,
  detailData: null,
  isOpenModal: false,
  modalType: "",
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_CUSTOMER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_CUSTOMER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_CUSTOMER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case POST_CUSTOMER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_CUSTOMER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_CUSTOMER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case PUT_CUSTOMER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_CUSTOMER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case DELETE_CUSTOMER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case RESET_CUSTOMER_PASSWORD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case RESET_CUSTOMER_PASSWORD_SUCCESS:
      const newState = {
        ...state,
        newPass: action.data.data.data.new_password,
        pending: false,
      };
      return newState;
    case RESET_CUSTOMER_PASSWORD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case CHANGE_PASSWORD_CUSTOMER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CHANGE_PASSWORD_CUSTOMER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case CHANGE_PASSWORD_CUSTOMER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case CUSTOMER_DATA:
      return {
        ...state,
        detailData: action.data,
      };
    case SET_CUSTOMER_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default customer;
