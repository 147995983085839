import API from "../API";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
// import { toastSuccess, toastError } from "../../../components/commons/toast";
import getToken from "../../../utils/token";
// import actions from "redux-form/lib/actions";
import { setToastModal } from "../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_ORDER_PENDING = "GET_ORDER_PENDING";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";
export const POST_ORDER_PENDING = "POST_ORDER_PENDING";
export const POST_ORDER_SUCCESS = "POST_ORDER_SUCCESS";
export const POST_ORDER_ERROR = "POST_ORDER_ERROR";
export const PUT_ORDER_PENDING = "PUT_ORDER_PENDING";
export const PUT_ORDER_SUCCESS = "PUT_ORDER_SUCCESS";
export const PUT_ORDER_ERROR = "PUT_ORDER_ERROR";
export const DELETE_ORDER_PENDING = "DELETE_ORDER_PENDING";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_ERROR = "DELETE_ORDER_ERROR";
export const CHECKOUT_ORDER_PENDING = "CHECKOUT_ORDER_PENDING";
export const CHECKOUT_ORDER_SUCCESS = "CHECKOUT_ORDER_SUCCESS";
export const CHECKOUT_ORDER_ERROR = "CHECKOUT_ORDER_ERROR";
export const SAVE_ORDER_PENDING = "SAVE_ORDER_PENDING";
export const SAVE_ORDER_SUCCESS = "SAVE_ORDER_SUCCESS";
export const SAVE_ORDER_ERROR = "SAVE_ORDER_ERROR";
export const IMPORT_ORDER_PENDING = "IMPORT_ORDER_PENDING";
export const IMPORT_ORDER_SUCCESS = "IMPORT_ORDER_SUCCESS";
export const IMPORT_ORDER_ERROR = "IMPORT_ORDER_ERROR";

export const ORDER_DATA = "ORDER_DATA";
export const SET_ORDER_DATA_MODAL = "SET_ORDER_DATA_MODAL";

// URL: URL_{URL}
const ORDER_URL = "/v1/order";
const CHECKOUT_ORDER_URL = "/v1/order/checkout";
const SAVE_ORDER_URL = "/v1/order/add";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_ORDER_PENDING));
  API.get(ORDER_URL, { params: param, headers: getToken()?.headers })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ORDER_SUCCESS, res));
      const records_total = res?.data.recordsTotal;
      let data = res?.data?.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ORDER_ERROR));
      // toastError(err.response?.data.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_ORDER_PENDING));
  API.post(ORDER_URL, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_ORDER_SUCCESS, res));
      // toastSuccess("Order berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Order berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_ORDER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_ORDER_PENDING));
  API.put(ORDER_URL, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_ORDER_SUCCESS, res));
      // toastSuccess("Order berhasil diubah");
      dispatch(setToastModal(true, true, "Order berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_ORDER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_ORDER_PENDING));
  API.delete(ORDER_URL, { params: param }, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_ORDER_SUCCESS, res));
      // toastSuccess("Order berhasil dihapus");
      dispatch(setToastModal(true, true, "Order berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_ORDER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const checkout = (param, callback) => (dispatch) => {
  dispatch(actionPending(CHECKOUT_ORDER_PENDING));
  API.post(CHECKOUT_ORDER_URL, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(CHECKOUT_ORDER_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(CHECKOUT_ORDER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const save = (param, callback) => (dispatch) => {
  dispatch(actionPending(SAVE_ORDER_PENDING));
  API.post(SAVE_ORDER_URL, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(SAVE_ORDER_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(SAVE_ORDER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const importExcel = (param, callback) => (dispatch) => {
  dispatch(actionPending(IMPORT_ORDER_PENDING));
  API.post(`${ORDER_URL}/import`, param, getToken())
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(IMPORT_ORDER_SUCCESS, res));
      // toastSuccess("Import Order berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Import Order berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(IMPORT_ORDER_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Order = { get, post, put, deleted, checkout, save, importExcel };
export default Order;

export const setOrderData = (data) => (dispatch) =>
  dispatch({ type: ORDER_DATA, data });

export const setOrderDataModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_ORDER_DATA_MODAL, {
      modalType: modalType,
      isOpen: isOpen,
    })
  );
