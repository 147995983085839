import React, { useState, useEffect } from "react";
import { Col, Row, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Notification from "../../../../store/actions/notification";
import { connect, useDispatch } from "react-redux";
import { getUser } from "../../../../utils/user";
import { history } from "../../../../utils";
import moment from "moment";
import IconDone from "../../../../assets/icon/icon-done";
import IconClear from "../../../../assets/icon/icon-clear";
import IconSend from "../../../../assets/icon/icon-send";
import IconShop from "../../../../assets/icon/icon-shop";

const Index = ({ fullName, email, notification }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prevState) => !prevState);
  const dispatch = useDispatch();
  const user = getUser();

  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = () =>
    dispatch(Notification.get({ length: 4, from_web: true }));
  const readNotification = (id = "") => {
    dispatch(Notification.post({ id: id }));
    getNotification();
  };

  return (
    <Row className="m-0">
      <Col style={{ textAlign: "end" }}>
        <label className="account-name m-0">{fullName}</label>
        <span className="account-mail m-0 d-block">{email}</span>
      </Col>
      {user?.role_code === "customer" || user?.role_code === "sales_admin" ? (
        <Dropdown isOpen={isOpen} toggle={toggle} size="sm">
          <DropdownToggle
            tag="div"
            data-toggle="dropdown"
            aria-expanded={isOpen}
            className={`mt-2 pointer ${
              notification?.data?.data?.data.filter(
                (item) => item.is_read === 0
              ).length > 0
                ? "beep"
                : ""
            }`}
          >
            <NotificationsIcon />
          </DropdownToggle>
          <DropdownMenu
            right
            className="notification"
            modifiers={{
              setMaxHeight: {
                enabled: true,
                order: 890,
                fn: (data) => {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      overflow: "auto",
                      maxHeight: "500px",
                    },
                  };
                },
              },
            }}
          >
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center p-3">
                <span
                  style={{ color: "#9095B3", fontSize: 18, fontWeight: 600 }}
                >
                  Notifikasi
                </span>
                <a
                  style={{
                    color: "#1C37BE",
                    fontSize: 12,
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => readNotification()}
                >
                  Tandai telah dibaca
                </a>
              </div>
              {notification?.data?.data?.data
                ?.slice(0, 4)
                .map((item, index) => {
                  let type = item.notification_type;
                  let now = moment(new Date());
                  let end = moment(item.created_at);

                  let text;
                  if (now.diff(end, "minutes") < 60) {
                    text = `${now.diff(end, "minutes")} menit yang lalu`;
                  } else if (now.diff(end, "hours") < 24) {
                    text = `${now.diff(end, "hours")} jam yang lalu`;
                  } else {
                    text = moment(item.created_at).format(
                      "dddd, DD MMMM YYYY, HH:mm"
                    );
                  }

                  return (
                    <div
                      className={`d-flex border-bottom p-3 pointer `}
                      onClick={() => readNotification(item.id)}
                    >
                      <div
                        key={index}
                        style={{
                          backgroundColor:
                            type === "Order Baru"
                              ? "#5ADD70"
                              : type === "Order Dikirim"
                              ? "#1c37be"
                              : type === "Order Diproses"
                              ? "#ff691b"
                              : "#FC544B",
                          borderRadius: "50%",
                          width: 35,
                          height: 35,
                          padding: 5,
                          margin: "auto 10px auto 0px",
                        }}
                      >
                        {type === "Order Baru" ? (
                          <IconDone />
                        ) : type === "Order Dikirim" ? (
                          <IconSend />
                        ) : type === "Order Diproses" ? (
                          <IconShop />
                        ) : type === "Order Dicancel" ? (
                          <IconClear />
                        ) : null}
                      </div>
                      <div className="d-flex flex-column">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.message,
                          }}
                          style={{ fontSize: 12 }}
                        />
                        <span
                          style={{
                            fontSize: 12,
                            color: `${
                              item.is_read === 0 ? "#96bdfe" : "#777777"
                            }`,
                          }}
                        >
                          {text}
                        </span>
                      </div>
                    </div>
                  );
                })}
              <div className="d-flex justify-content-center align-items-center p-3">
                <a
                  style={{
                    color: "#1C37BE",
                    fontSize: 14,
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/notification")}
                >
                  Lihat Semua{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <div
          style={{
            height: 50,
            width: 50,
            borderRadius: "50%",
            backgroundColor: "#1c37be",
            margin: "auto",
          }}
        />
      )}
    </Row>
  );
};

const mapStateToProps = ({ notification }) => {
  return {
    notification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
