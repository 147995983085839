import API from "../../API";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";
// import { toastSuccess, toastError } from "../../../../components/commons/toast";
import { setToastModal } from "../../../actions";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_WAREHOUSE_PENDING = "GET_WAREHOUSE_PENDING";
export const GET_WAREHOUSE_SUCCESS = "GET_WAREHOUSE_SUCCESS";
export const GET_WAREHOUSE_ERROR = "GET_WAREHOUSE_ERROR";
export const POST_WAREHOUSE_PENDING = "POST_WAREHOUSE_PENDING";
export const POST_WAREHOUSE_SUCCESS = "POST_WAREHOUSE_SUCCESS";
export const POST_WAREHOUSE_ERROR = "POST_WAREHOUSE_ERROR";
export const PUT_WAREHOUSE_PENDING = "PUT_WAREHOUSE_PENDING";
export const PUT_WAREHOUSE_SUCCESS = "PUT_WAREHOUSE_SUCCESS";
export const PUT_WAREHOUSE_ERROR = "PUT_WAREHOUSE_ERROR";
export const DELETE_WAREHOUSE_PENDING = "DELETE_WAREHOUSE_PENDING";
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS";
export const DELETE_WAREHOUSE_ERROR = "DELETE_WAREHOUSE_ERROR";

export const WAREHOUSE_DATA = "WAREHOUSE_DATA";
export const SET_WAREHOUSE_MODAL = "SET_WAREHOUSE_MODAL";

// URL: URL_{URL}
const WAREHOUSE_URL = "/v1/warehouse";

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_WAREHOUSE_PENDING));
  API.get(WAREHOUSE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_WAREHOUSE_SUCCESS, res));
      const records_total = res.data.recordsTotal;
      let data = res.data.data.map((item, i) => ({
        ...item,
        no: i + 1 + (param?.page - 1) * param?.length,
      }));
      if (resolve) {
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_WAREHOUSE_ERROR));
      // toastError(err.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_WAREHOUSE_PENDING));
  API.post(WAREHOUSE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_WAREHOUSE_SUCCESS, res));
      // toastSuccess("Gudang berhasil ditambahkan");
      dispatch(setToastModal(true, true, "Gudang berhasil ditambahkan"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_WAREHOUSE_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_WAREHOUSE_PENDING));
  API.put(WAREHOUSE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_WAREHOUSE_SUCCESS, res));
      // toastSuccess("Gudang berhasil diubah");
      dispatch(setToastModal(true, true, "Gudang berhasil diubah"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_WAREHOUSE_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_WAREHOUSE_PENDING));
  API.delete(WAREHOUSE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_WAREHOUSE_SUCCESS, res));
      // toastSuccess("Gudang berhasil dihapus");
      dispatch(setToastModal(true, true, "Gudang berhasil dihapus"));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_WAREHOUSE_ERROR));
      // toastError(err?.response?.data?.message);
      dispatch(setToastModal(true, false, err?.response?.data?.message));
    });
};

const Warehouse = { get, post, put, deleted };
export default Warehouse;

export const setWarehouseData = (data) => (dispatch) =>
  dispatch({ type: WAREHOUSE_DATA, data });

export const setWarehouseModal = (modalType, isOpen) => (dispatch) =>
  dispatch(
    actionSuccess(SET_WAREHOUSE_MODAL, { modalType: modalType, isOpen: isOpen })
  );
